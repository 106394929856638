import React, {useEffect, useState} from 'react'

import {Col} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect, useDispatch, useSelector} from 'react-redux'

import {GetEmployeeFittingById} from '../../../infra/requests/FittingRequests'
import {removeProductFromBag} from '../../../infra/utils/BagActions'
import Currency from '../../../infra/utils/Currency'
import QuickAddToBagButton from '../../../shared/components/buttons/QuickAddToBagButton'
import GetImage from '../../../shared/components/Image'
import ComponentLoadingOverlay from '../../../shared/components/loading/ComponentLoadingOverlay'
import {
  CategoryTag,
  CreditsTag,
  CreditsWrapper,
  ExtraCreditsTag,
  ImageLink,
  ImageP,
  ImagePanel,
  NameTag,
  PriceTag,
  ProductPanel,
  Separator,
  WorkgroupTag
} from './ProductCardStyle'

const ProductCard = ({
  user,
  translate,
  product,
  fastAddToBag,
  activeLanguage
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  const {bagItems} = useSelector((state) => state.bag)

  const bagItem = bagItems.find(
    (item) => item.productId === product.productId
  )

  const hasExtraCredits = product?.extraCreditsAvailable > 0

  const hasCredits =
    product?.extraCreditsAvailable + product?.creditsAvailable > 0 &&
    product?.extraCreditsAvailable + product?.creditsAvailable >
      (bagItem?.units || 0)

  const displayCost =
    hasCredits || !user.allowOrdersWithMoney
      ? `${product?.dotation} ${
          product?.dotation < 2
            ? translate('CART_CREDIT')
            : translate('CART_CREDITS')
        }`
      : Currency.format(product.productPrice, user.currencyCode)

  const handleFastAddClick = async () => {
    setIsLoading(true)
    if (bagItem) {
      await removeProductFromBag(bagItem.bagItemId, dispatch)
    } else {
      await fastAddToBag(product)
    }
    setIsLoading(false)
  }

  return (
    <Col xs={12} lg={12}>
      <ComponentLoadingOverlay isLoading={isLoading}>
        <ProductPanel>
          <CategoryTag
            style={{color: user.configuration?.layoutColors?.contentText}}
          >
            {
              product?.product?.mainCategory?.categoryTranslation?.find(
                (x) => x.languageId === activeLanguage.code
              )?.name
            }
          </CategoryTag>
          <NameTag>
            {
              product?.product?.productTranslation?.find(
                (x) => x.languageId === activeLanguage.code
              )?.name
            }
          </NameTag>
          <WorkgroupTag>{product?.employee?.position?.name}</WorkgroupTag>
          <ImagePanel>
            <QuickAddToBagButton
              handleFastAddClick={handleFastAddClick}
              bagItem={bagItem}
              user={user}
              unisize={product?.product?.productCombination?.length === 1}
            />
            <ImageLink to={`/products/${product?.productId}`}>
              <ImageP
                src={GetImage(
                  product?.product?.productImage.find(
                    (x) => x.cover === true
                  )
                )}
                alt='IMG_LOGO'
              />
            </ImageLink>
          </ImagePanel>
          <PriceTag
            style={{color: user.configuration?.layoutColors?.contentText}}
          >
            {displayCost}
          </PriceTag>
          <Separator />
          <CreditsTag>
            <Translate id='USED_CREDITS' />{' '}
            <CreditsWrapper
              style={{
                color: user.configuration?.layoutColors?.contentText
              }}
            >
              ({Math.max(0, product?.dotation - product?.creditsAvailable)}{' '}
              / {product?.dotation})
            </CreditsWrapper>
            {hasExtraCredits && (
              <ExtraCreditsTag>extra credits</ExtraCreditsTag>
            )}
          </CreditsTag>
        </ProductPanel>
      </ComponentLoadingOverlay>
    </Col>
  )
}

ProductCard.propTypes = {
  translate: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  fastAddToBag: PropTypes.func.isRequired,
  activeLanguage: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default withLocalize(connect(mapStateToProps)(ProductCard))
