import {GetEmployeeBag} from '../../infra/requests/BagRequests'
import {GetOtherItems} from '../../infra/requests/ProductRequests'
import {bagSave} from '../data/bag/BagActions'
import {relatedSave} from '../data/related/RelatedActions'

export function GetAndUpdateBag() {
  return async (dispatch) => {
    try {
      const {success, data} = await GetEmployeeBag()
      if (success) {
        dispatch(bagSave(data))
      }
    } catch (error) {
      console.warn(error)
    }
  }
}

export function GetAndUpdateRelated() {
  return async (dispatch) => {
    try {
      const {success, data} = await GetOtherItems()
      if (success) {
        dispatch(relatedSave(data))
      }
    } catch (error) {
      console.warn(error)
    }
  }
}
