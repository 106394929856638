import React, {useState} from 'react'

import PropTypes from 'prop-types'
import {connect, useSelector} from 'react-redux'

import ShoppingBag from '../bag/Bag'
import Burger from '../Burger/Burger'
import IconContainer from '../icon-container/IconContainer'
import LanguageContainer from '../language-container/LanguageContainer'
import Menu from '../menu/Menu'
import MobileMenu from '../mobile-menu/MobileMenu'
import WarningCookies from '../warning-cookies/CookiesWarning'
import {
  HamburgerMenu,
  HeaderContainer,
  HeaderContent,
  LogoContainerLink,
  LogoImg
} from './NavBarStyle'

const Navbar = ({user}) => {
  const [bagHidden, setBag] = useState(true)

  const [cookiesShow, setCookies] = useState(
    !localStorage.getItem('hideCookie')
  )
  const [collapsed, setCollapsed] = useState(true)
  const [mobileMenu, setMobileMenu] = useState(false)

  const {bagItems} = useSelector((state) => state.bag)

  const handleCookies = () => {
    localStorage.setItem('hideCookie', true)
    setCookies(false)
  }

  return (
    <>
      <HeaderContainer
        mobileMenu={mobileMenu}
        collapsed={collapsed}
        style={{
          backgroundColor: user.configuration?.layoutColors?.primary,
          borderBottom: !collapsed
            ? '4px solid transparent'
            : user.configuration?.layoutColors?.headerBorder === true
            ? `0px solid ${user.configuration?.layoutColors?.secondary}`
            : '1px solid rgba(76,76,76,.16)'
        }}
      >
        <HeaderContent>
          <HamburgerMenu onClick={() => setMobileMenu(!mobileMenu)}>
            <Burger collapsed={collapsed} setCollapsed={setCollapsed} />
          </HamburgerMenu>
          <LogoContainerLink to='/outfit'>
            {user.configuration?.logo !== '' ? (
              <img
                src={user.configuration?.logo}
                alt={`${user.configuration?.title}`}
                style={{height: '40px'}}
              />
            ) : (
              <LogoImg />
            )}
          </LogoContainerLink>
          <Menu user={user} />
          <LanguageContainer user={user} />
          <IconContainer
            configuration={user.configuration}
            setBagHidden={() => setBag(!bagHidden)}
            bagSize={bagItems.reduce(
              (total, item) => (total += item.units),
              0
            )}
          />
          <MobileMenu
            setMobileMenu={() => setMobileMenu(!mobileMenu)}
            open={mobileMenu}
            user={user}
          />
          <ShoppingBag
            open={!bagHidden}
            closeBag={() => setBag(!bagHidden)}
            bag={bagItems}
          />
        </HeaderContent>
      </HeaderContainer>
      {cookiesShow && <WarningCookies setCookiesShow={handleCookies} />}
    </>
  )
}

Navbar.propTypes = {
  user: PropTypes.object
}

Navbar.defaultProps = {
  user: {}
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default connect(mapStateToProps)(Navbar)
