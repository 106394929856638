import React from 'react'

import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'
import {useParams} from 'react-router'
import {Element, scroller} from 'react-scroll'

import DetailsInfo from '../../../shared/components/details-info/DetailsInfo'
import ProductForm from '../../../shared/components/forms/product-form/ProductForm'
import GetImage from '../../../shared/components/Image'
import BaseLoading from '../../../shared/components/loading/BaseLoading'
import ProHealth from '../../../shared/components/ProHealth/ProHealth'
import Related from '../../../shared/components/related/Related'
import getTranslationNameFromArray from '../../../shared/utils/getTranslationNameFromArray'
import useProductDetails from '../hooks/useProductDetails'
import {
  Image,
  ImageWrapper,
  ProductCarousel,
  ProductImageAside,
  ProductDetailSection,
  ProductDetailWrapper,
  ProductDetailAside,
  DetailsWrapper,
  DetailsViewport,
  DetailsContent,
  DetailsContainer,
  DetailsCategory,
  DetailsTitle,
  FloatList,
  FloatListItem,
  FloatListItemLink,
  FloatListItemText
} from '../ProductPageStyle'
import SliderArrow from './SliderArrow'

const settings = {
  className: 'center',
  centerMode: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  swipeToSlide: true,
  slidesToScroll: 1,
  dots: false,
  arrows: true,
  nextArrow: <SliderArrow />
}

const ProductDetail = ({user, active, setActive}) => {
  const {id} = useParams()
  const {languageId} = user
  const {
    isLoading,
    productDetails,
    colorObject,
    sizesObject,
    technologiesObject,
    relatedProducts
  } = useProductDetails(id)

  if (isLoading) {
    return <BaseLoading />
  }

  return (
    <>
      <Element id='products' name='products' style={{paddingTop: '72px'}}>
        <ProductDetailSection>
          <ProductDetailWrapper>
            <ProductImageAside>
              <ProductCarousel {...settings}>
                {productDetails?.product?.productImage.map((image) => (
                  <ImageWrapper key={image?.imageId}>
                    <Image src={GetImage(image)} alt='' />
                  </ImageWrapper>
                ))}
              </ProductCarousel>
            </ProductImageAside>
            <ProductDetailAside>
              <DetailsWrapper>
                <DetailsViewport>
                  <DetailsContent>
                    <DetailsContainer>
                      <DetailsCategory
                        style={{
                          color:
                            user?.configuration?.layoutColors?.contentText
                        }}
                      >
                        {getTranslationNameFromArray(
                          productDetails.product?.mainCategory
                            ?.categoryTranslation,
                          languageId
                        )}
                      </DetailsCategory>
                      <DetailsTitle>
                        {getTranslationNameFromArray(
                          productDetails.product?.productTranslation,
                          languageId
                        )}
                      </DetailsTitle>
                      <ProductForm
                        productDetails={productDetails}
                        colorObject={colorObject}
                        sizesObject={sizesObject}
                      />
                    </DetailsContainer>
                  </DetailsContent>
                </DetailsViewport>
              </DetailsWrapper>
            </ProductDetailAside>
            <FloatList>
              <FloatListItem>
                <FloatListItemLink
                  activeClass='active'
                  to='products'
                  spy
                  className={active === 'products' ? 'products' : ''}
                  onClick={() => {
                    setActive('products')
                    scroller.scrollTo('products')
                  }}
                >
                  <FloatListItemText>
                    <Translate id='PRODUCT' />
                  </FloatListItemText>
                </FloatListItemLink>
              </FloatListItem>

              {technologiesObject.length > 0 && (
                <FloatListItem>
                  <FloatListItemLink
                    activeClass='active'
                    to='technology'
                    spy
                    className={active === 'technology' ? 'technology' : ''}
                    onClick={() => {
                      setActive('technology')
                      scroller.scrollTo('technology')
                    }}
                  >
                    <FloatListItemText>
                      <Translate id='TECHNOLOGY' />
                    </FloatListItemText>
                  </FloatListItemLink>
                </FloatListItem>
              )}

              {relatedProducts?.length > 0 && (
                <FloatListItem>
                  <FloatListItemLink
                    activeClass='active'
                    to='related'
                    spy
                    className={active === 'related' ? 'related' : ''}
                    onClick={() => {
                      setActive('related')
                      scroller.scrollTo('related')
                    }}
                  >
                    <FloatListItemText>
                      <Translate id='RELATED' />
                    </FloatListItemText>
                  </FloatListItemLink>
                </FloatListItem>
              )}
            </FloatList>
          </ProductDetailWrapper>
        </ProductDetailSection>
      </Element>
      {productDetails?.product?.productCharacteristicValue.length > 0 && (
        <Element
          id='details'
          name='details'
          style={{backgroundColor: 'white'}}
        >
          <DetailsInfo
            user={user}
            info={
              productDetails?.product?.productCharacteristicValue || []
            }
          />
        </Element>
      )}
      {technologiesObject.length > 0 && (
        <Element id='technology' name='technology'>
          <ProHealth user={user} technologiesObject={technologiesObject} />
        </Element>
      )}
      {relatedProducts?.length > 0 && (
        <Element id='related' name='related'>
          <Related
            relatedProducts={relatedProducts}
            sizesObject={sizesObject}
          />
        </Element>
      )}
    </>
  )
}

ProductDetail.propTypes = {
  user: PropTypes.object.isRequired,
  active: PropTypes.string.isRequired,
  setActive: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default withLocalize(connect(mapStateToProps)(ProductDetail))
