import React from 'react'

import {Steps} from 'antd'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import Currency from '../../../infra/utils/Currency'
import formatDate from '../../../shared/utils/formatDate'
import {
  BigProgressBar,
  HalfTable,
  LineText,
  LinkText,
  OrderAside,
  OrderInfoAside,
  OrderInfoTable,
  OrderStatusAside,
  OrderSummary,
  OrderTable,
  OrderTableItems,
  OrderTableTitle,
  OrderTableWrapper,
  ProblemMailLink,
  StatusBarBig,
  SummaryAside,
  SummarySpan,
  SummaryTableItems,
  SummaryTitle
} from '../OrdersStyle'
import ProductOrderRow from './ProductOrderRow'

const sumQuantity = (orderItem) => {
  let quantity = 0

  orderItem.map((item) => (quantity += item.quantity))

  return quantity
}

const {Step} = Steps

const OrderItemSummary = ({
  open,
  order,
  regularDataSteps,
  returnDataSteps,
  user,
  translate
}) => {
  const shouldRenderOrderInfoTable =
    order?.attachment.length > 0 ||
    order?.notes !== '-' ||
    order?.trackingList.length > 0

  const isOpen = open.status && order?.orderID === open.id ? '1' : '0'

  return (
    <OrderSummary open={isOpen}>
      <OrderTable>
        <OrderAside>
          <OrderTableWrapper>
            <OrderTableTitle>
              <Translate id='DELIVERY_ADDRESS' />
            </OrderTableTitle>
            <OrderTableItems>
              {order?.deliveryAddressLine1}
              <br />
              {order?.deliveryAddressLine2 !== '' ||
              order?.deliveryAddressLine3 !== ''
                ? `${order?.deliveryAddressLine2} ${order?.deliveryAddressLine3}`
                : ''}
              {order?.deliveryAddressLine2 !== '' ||
              order?.deliveryAddressLine3 !== '' ? (
                <br />
              ) : (
                ''
              )}
              {order?.deliveryStreetNumber !== ''
                ? order?.deliveryStreetNumber
                : ''}
              {order?.deliveryStreetNumber !== '' ? <br /> : ''}
              {order?.deliveryZipCode} {order?.deliveryCity}{' '}
              {order?.deliveryRegion}, {order?.deliveryCountryName}
            </OrderTableItems>
            <OrderTableItems>
              <ProblemMailLink
                href={`mailto:info@wearskypro.com?subject=${translate(
                  'SUBJECT_PROBLEM_EMAIL'
                )} #${
                  order?.orderRelatedID > 0
                    ? order?.number
                    : order?.orderID
                }`}
                style={{
                  color: user.configuration?.layoutColors?.contentText
                }}
              >
                <Translate id='PROBLEM_CONTACT' />
              </ProblemMailLink>
            </OrderTableItems>
          </OrderTableWrapper>
        </OrderAside>
        <SummaryAside>
          <OrderTableWrapper>
            <OrderTableTitle>
              <Translate id='ORDER_SUMMARY' />
            </OrderTableTitle>
            <SummaryTableItems>
              <SummaryTitle style={{marginTop: 8}}>
                <Translate id='ITEMS' />
              </SummaryTitle>
              <SummarySpan>{sumQuantity(order?.orderItem)}</SummarySpan>
            </SummaryTableItems>
            <SummaryTableItems>
              <SummaryTitle>
                <Translate id='POSTAGE_PACKING' />
              </SummaryTitle>
              <SummarySpan>
                {Currency.format(0, order?.currencyCode)}
              </SummarySpan>
            </SummaryTableItems>
            <SummaryTableItems>
              <SummaryTitle style={{marginBottom: 0}}>
                <strong>
                  <Translate id='ORDER_TOTAL' />
                </strong>
              </SummaryTitle>
              <SummarySpan>
                <strong>
                  {Currency.format(
                    order?.usesCredits ? 0 : order?.totalValue,
                    order?.currencyCode
                  )}
                </strong>
              </SummarySpan>
            </SummaryTableItems>
          </OrderTableWrapper>
        </SummaryAside>
        <OrderStatusAside>
          <HalfTable>
            <OrderTableTitle>
              <Translate id='DATE_SUBMISSION' />
            </OrderTableTitle>
            <OrderTableItems>
              <SummaryTitle>
                {formatDate(order?.date).dayOfMonth}{' '}
                {formatDate(order?.date).monthName}{' '}
                {formatDate(order?.date).year}
              </SummaryTitle>
            </OrderTableItems>
          </HalfTable>
          <HalfTable>
            <OrderTableTitle>
              <Translate id='ESTIMATED_DELIVERY' />
            </OrderTableTitle>
            <OrderTableItems>
              <SummaryTitle>
                {order?.dateDelivery
                  ? `${formatDate(order.dateDelivery).dayOfMonth} ${
                      formatDate(order.date).monthName
                    } ${formatDate(order.date).year}`
                  : '-'}
              </SummaryTitle>
            </OrderTableItems>
          </HalfTable>
          <HalfTable>
            <OrderTableTitle>
              <Translate id='PAYMENT_STATE' />
            </OrderTableTitle>
            <OrderTableItems>
              <SummaryTitle style={{marginBottom: 0}}>
                {order.paid === 1 ? (
                  <Translate id='PAYMENT_RECEIVED' />
                ) : (
                  <Translate id='PAYMENT_PENDING' />
                )}
              </SummaryTitle>
            </OrderTableItems>
          </HalfTable>
          {order.paid === 0 ? (
            <OrderTableWrapper>
              <OrderTableTitle />
              <OrderTableItems>
                <ProblemMailLink
                  style={{
                    color: user.configuration?.layoutColors?.contentText
                  }}
                >
                  <Translate id='GET_REFERENCE' />
                </ProblemMailLink>
              </OrderTableItems>
            </OrderTableWrapper>
          ) : (
            ''
          )}
        </OrderStatusAside>
      </OrderTable>
      {shouldRenderOrderInfoTable && (
        <OrderInfoTable>
          <OrderInfoAside>
            <OrderTableWrapper>
              <OrderTableTitle>
                <Translate id='DOCUMENTS' />
              </OrderTableTitle>
              <OrderTableItems>
                {order?.attachment.length > 0
                  ? order?.attachment.map((doc, index) => {
                      const linkUrl = doc.name
                      return (
                        <LineText key={index}>
                          <LinkText href={linkUrl} target='_blank'>
                            {doc.name}
                          </LinkText>
                        </LineText>
                      )
                    })
                  : '-'}
              </OrderTableItems>
            </OrderTableWrapper>
          </OrderInfoAside>
          <OrderInfoAside>
            <OrderTableWrapper>
              <OrderTableTitle>
                <Translate id='NOTES' />
              </OrderTableTitle>
              <OrderTableItems>{order?.notes}</OrderTableItems>
            </OrderTableWrapper>
          </OrderInfoAside>
          <OrderInfoAside>
            <OrderTableWrapper>
              <OrderTableTitle>
                <Translate id='TRACKING' />
                {' #'}
              </OrderTableTitle>
              <OrderTableItems>
                {order?.trackingList.length > 0
                  ? order?.trackingList.map((tracking) => tracking)
                  : '-'}
              </OrderTableItems>
            </OrderTableWrapper>
          </OrderInfoAside>
        </OrderInfoTable>
      )}
      <StatusBarBig>
        <BigProgressBar
          size='large'
          current={order?.orderStatus?.orderStatusId}
          progressDot
        >
          {order?.orderRelatedID === 0
            ? regularDataSteps?.map((product, index) => (
                <Step
                  key={index}
                  title={
                    product?.orderStatusTranslation?.find(
                      (x) => x.languageId === user.languageId
                    )?.name
                  }
                />
              ))
            : returnDataSteps?.map((product, index) => (
                <Step
                  key={index}
                  title={
                    product?.orderStatusTranslation?.find(
                      (x) => x.languageId === user.languageId
                    )?.name
                  }
                />
              ))}
        </BigProgressBar>
      </StatusBarBig>
      {order?.orderItem?.map((product, index) => (
        <ProductOrderRow
          key={index}
          product={product}
          order={order}
          user={user}
        />
      ))}
    </OrderSummary>
  )
}

OrderItemSummary.propTypes = {
  open: PropTypes.object.isRequired,
  regularDataSteps: PropTypes.array,
  returnDataSteps: PropTypes.array,
  order: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired
}

OrderItemSummary.defaultProps = {
  regularDataSteps: undefined,
  returnDataSteps: undefined
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default withLocalize(connect(mapStateToProps)(OrderItemSummary))
