import styled from 'styled-components'

export const OverlayContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`

export const OverlayContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0) 80%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
  z-index: 1000;
`

export const StyledLoadingContainer = styled.div`
  display: flex;
  margin-top: 72px;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`
