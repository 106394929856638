import React from 'react'

import {PropTypes} from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import RelatedProductCard from './RelatedProductCard'
import {
  Container,
  Heading,
  ListItem,
  RelatedProductsList,
  RelatedProductsWrapper,
  Title,
  TitleWrapper
} from './RelatedStyles'

const Related = ({user, relatedProducts}) => (
  <Container>
    <Heading>
      <TitleWrapper>
        <Title
          style={{color: user.configuration?.layoutColors?.contentText}}
        >
          <Translate id='RELATED_TITLE' />
        </Title>
      </TitleWrapper>
    </Heading>
    <RelatedProductsWrapper>
      <RelatedProductsList>
        {relatedProducts?.map((product, index) => (
          <ListItem key={index}>
            <RelatedProductCard product={product} />
          </ListItem>
        ))}
      </RelatedProductsList>
    </RelatedProductsWrapper>
  </Container>
)

Related.propTypes = {
  relatedProducts: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default withLocalize(connect(mapStateToProps)(Related))
