import React from 'react'

import {Spin} from 'antd'

import {StyledLoadingContainer} from './LoadingStyles'

const BaseLoading = () => (
  <StyledLoadingContainer>
    <Spin />
  </StyledLoadingContainer>
)

export default BaseLoading
