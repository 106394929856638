import React from 'react'

import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'

import BaseLoading from '../../../shared/components/loading/BaseLoading'
import {OrderRow} from '../OrdersStyle'
import OrderItem from './OrderItem'

const OrderList = ({
  orders,
  isLoading,
  open,
  setOpen,
  regularDataSteps,
  returnDataSteps,
  windowWidth
}) => {
  if (isLoading) return <BaseLoading />

  if (!orders.length) {
    return (
      <OrderRow>
        <Translate id='NO_ORDERS' />
      </OrderRow>
    )
  }
  return (
    <>
      {orders?.map((order, index) => (
        <OrderItem
          key={index}
          open={open}
          setOpen={setOpen}
          regularDataSteps={regularDataSteps}
          returnDataSteps={returnDataSteps}
          windowWidth={windowWidth}
          order={order}
        />
      ))}
    </>
  )
}

OrderList.propTypes = {
  orders: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  open: PropTypes.object.isRequired,
  setOpen: PropTypes.func.isRequired,
  regularDataSteps: PropTypes.array,
  returnDataSteps: PropTypes.array,
  windowWidth: PropTypes.number.isRequired
}

OrderList.defaultProps = {
  regularDataSteps: undefined,
  returnDataSteps: undefined
}

export default OrderList
