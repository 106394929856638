import React from 'react'

import {Translate} from 'react-localize-redux'

import {EachTitle, TitleWrapper} from '../OrdersStyle'

const tableHeaderData = [
  {id: 'ORDER'},
  {id: 'DATE'},
  {id: 'STATUS'},
  {id: 'DELIVERY'},
  {id: 'TOTAL'}
]

const OrderListHeader = () => (
  <TitleWrapper>
    {tableHeaderData.map((headerItem, index) => (
      <EachTitle key={index}>
        <Translate id={headerItem.id} />
      </EachTitle>
    ))}
  </TitleWrapper>
)

export default OrderListHeader
