import {createReducer} from '@reduxjs/toolkit'

import {bagSave, bagEdit, bagDelete, bagUpdateTotal} from './BagActions'

const initialState = {
  bagItems: [],
  bagTotal: 0
}

const calculateTotal = (items) =>
  items.reduce((sum, item) => {
    if (!item.isCredits) {
      return sum + item.unitValueConverted * item.units
    }
    return sum
  }, 0)

const BagReducer = createReducer(initialState, {
  [bagSave]: (state, action) => {
    state.bagItems = action.payload
    state.bagTotal = calculateTotal(action.payload)
  },
  [bagEdit]: (state, action) => ({...state, ...action.payload}),
  [bagDelete]: () => initialState,
  [bagUpdateTotal]: (state) => {
    state.bagTotal = calculateTotal(state.bagItems)
  }
})

export default BagReducer
