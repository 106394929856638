import LocaleString from '../../infra/constants/LocaleString'

const getMonthName = (month, languageId) => {
  const date = new Date()
  date.setMonth(month)
  const monthName = date
    .toLocaleString(LocaleString[languageId]?.value, {month: 'short'})
    .replace('.', '')
  return monthName
}

const getFullMonth = (month, languageId) => {
  const date = new Date()
  date.setMonth(month)
  const monthName = date
    .toLocaleString(LocaleString[languageId]?.value, {month: 'long'})
    .replace('.', '')
  return monthName
}

const formatDate = (obj, languageId) => {
  const date = new Date(obj)
  const month = date.getMonth()
  const monthName = getMonthName(month, languageId)
  const fullMonthName = getFullMonth(month, languageId)
  const dayOfWeek = date.getDay()
  const dayOfMonth = date.getDate()
  const year = date.getFullYear()

  const formattedDate = {
    dayOfWeek,
    dayOfMonth,
    monthName,
    fullMonthName,
    year
  }

  return formattedDate
}

export default formatDate
