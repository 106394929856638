import React from 'react'

import {LoadingOutlined} from '@ant-design/icons'
import {Checkbox, Select} from 'antd'
import styled from 'styled-components'

import {
  PageBackground,
  PrimaryTextColor,
  ErrorColor,
  Grey,
  TertiaryColor,
  SecondaryColor,
  TableTextColor,
  BorderColor,
  PrimaryButtonColor
} from '../../styles/_colors'
import {device} from '../../styles/_responsive'
import {
  BodyText,
  FooterText,
  SemiBoldFontWeight
} from '../../styles/_texts'

export const CheckboxField = styled(Checkbox)`
  & .ant-checkbox {
    width: 17px;
    height: 17px;
    border-radius: 50%;

    & + span {
      padding-left: 0px;
      padding-right: 0px;
    }

    & .ant-checkbox-input {
      width: 17px;
      height: 17px;
      border-radius: 50%;
    }

    & .ant-checkbox-inner {
      width: 17px;
      height: 17px;
      border-radius: 50%;
      border: 1px solid ${PrimaryTextColor};
    }

    &.ant-checkbox-checked {
      width: 17px;
      height: 17px;
      border-radius: 50%;
      border: 1px solid ${PrimaryTextColor};
      border-radius: 50%;

      &:after {
        border: none;
      }
      & .ant-checkbox-inner {
        background-color: ${PrimaryTextColor};
        content: ' ' !important;
        width: 9px;
        height: 9px;
        left: 3px;
        top: 3px;

        &:after {
          transform: none;
          opacity: 0;
        }
      }
    }

    &:hover {
      & .ant-checkbox-inner {
        border-color: ${(p) => p.theme.primaryCTA};
      }
    }
  }

  &.ant-checkbox-wrapper {
    display: flex;
    align-items: center;

    &.login_checkbox {
      float: left;
    }
    &:hover {
      & .ant-checkbox-inner {
      }
    }
  }

  & .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${(p) => p.theme.primaryCTA};
  }
`

/** ***************************** TEXT AREA INPUT ****************************** */

export const TextArea = styled.textarea`
  width: 100%;
  min-height: 100px;
  background-color: transparent;
  resize: none;
  border: none;
  border-bottom: ${({showError}) =>
    showError ? `1px solid ${ErrorColor}` : 'none'};
  outline: none;
  margin-bottom: 40px;
`

/** ***************************** SELECT INPUT ****************************** */

export const Input = styled.input`
  position: relative;
  border: none;
  border-bottom: 1px solid hsla(240, 3%, 41%, 0.5);
  border-bottom: ${({showError}) =>
    showError
      ? `1px solid ${ErrorColor}`
      : '1px solid hsla(240, 3%, 41%, 0.5)'};
  padding: 10px 0;
  width: 100% !important;
  text-align: left;
  z-index: 1;
  color: ${PrimaryTextColor};
  background-color: transparent;
  outline: none;
  font-size: ${BodyText};
`

export const NormalSelect = styled(Select)`
  width: 100%;
  & .ant-select-selector {
    position: relative !important;
    border: none !important;
    border-bottom: ${({showError}) =>
      showError
        ? `1px solid ${ErrorColor}`
        : '1px solid hsla(240, 3%, 41%, 0.5)'} !important;
    padding: 10px 0 !important;
    width: 100% !important;
    text-align: left;
    z-index: 1;
    color: ${PrimaryTextColor};
    background-color: transparent !important;
    outline: none;
    font-size: ${BodyText};
  }
`

export const SecondarySelect = styled(NormalSelect)`
  & .ant-select-selector {
    padding: 10px 20px !important;
    width: 100% !important;
    border: 1px solid ${({showError}) =>
      showError ? 'red' : '#dedede'} !important;
    max-width: 100% !important;
    /* font: normal ${FooterText} 'Assistant' !important; */
    line-height: 30px;
    outline: none;
    margin: 0;
    display: block;
    border-radius: 3px !important;
    height: auto !important;
    color: ${SecondaryColor};

    &::placeholder {
      color: ${Grey};
    }
  }
`
/** ***************************** SIZES SELECT INPUT ****************************** */

export const SizesSelectInput = styled(Select)`
  width: 100%;
  height: auto;
  color: ${SecondaryColor};
  text-align: left;
`
/** ******************************* SORT BY SELECT INPUT ******************************* */

export const SortBySelectContainer = styled.div`
  width: 300px;
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
`

/** ******************************* SPECIAL SELECT INPUT ******************************* */

export const SpecialSelect = styled(Select)`
  & .ant-select-selector {
    font-family: 'Campton';
    text-transform: uppercase;
    font-size: 12px;
    padding: 9px 20px !important;
    border: 1px solid
      ${({showError}) => (showError ? 'red' : 'hsla(0, 0%, 71%, 0.47)')} !important;
    color: ${PageBackground};
    appearance: none;
    cursor: pointer;
    outline: none;
    line-height: 26px;
    border-radius: 3px !important;
    height: auto !important;
    background-color: ${TertiaryColor} !important;
    width: 150px !important;

    @media ${device.tabletSS} {
      width: 135px !important;
    }

    @media ${device.tabletS} {
      width: 94px !important;
    }

    @media ${device.mobileL} {
      width: 59px !important;
    }

    &:hover {
      border: 1px solid
        ${({showError}) =>
          showError ? ErrorColor : 'hsla(0, 0%, 71%, 0.47)'} !important;
    }
  }
`

export const StyledOption = styled(Select.Option)``

/** ******************************* SPECIAL CREATEACC INPUT ******************************* */

export const CreateAccountInput = styled.input`
  padding: 5px 20px;
  width: 100%;
  border: 1px solid
    ${({showError}) => (showError ? ErrorColor : 'hsla(0, 0%, 71%, 0.47)')};
  max-width: 100%;
  font: normal ${BodyText} 'Assistant';
  line-height: 30px;
  outline: none;
  margin: 0;
  display: block;
  border-radius: 3px;
  height: auto;
  color: ${SecondaryColor};

  &::placeholder {
    color: ${Grey};
  }
`
/** ******************************** LOGIN INPUT ******************************** */

export const LoginInput = styled.input`
  border-radius: 36px;
  font-family: 'Assistant';
  font-weight: regular;
  font-size: 14px;
  width: 100%;
  padding: 0px 10px;
  border: 1px solid
    ${({showError}) => (showError ? ErrorColor : 'hsla(0, 0%, 71%, 0.47)')};
  max-width: 100%;
  color: ${PrimaryTextColor};
  outline: none;
  margin: 0;
  text-align: center;
  display: block;
  height: 42px;
  background-color: ${TertiaryColor};
  background-image: none;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
`

export const InputDiv = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: ${(p) => (p.first ? '0px' : '10px')};
`

export const StyledSelectInput = styled(Select)`
  width: 100%;

  ${(p) =>
    p.error &&
    `.ant-select-selection{
        border: 1px solid ${p.theme.inputErrorColor} !important;
      }
     
  `}
`

export const NotesText = styled.div`
  font-size: 13px;
  padding-top: 5px;
`

export const InputBox = styled.div`
  display: flex;
  flex-direction: ${({$row}) => ($row ? 'row' : 'column')};
  width: 100%;
  position: relative;
  padding-bottom: ${({hasPaddingBottom}) =>
    hasPaddingBottom ? '20px' : '0px'};
`

export const InputLabel = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: ${TableTextColor};
  margin-left: 10px;
  font-weight: ${SemiBoldFontWeight};
`

export const NotFoundContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
  font-size: 16px;

  .anticon {
    font-size: 46px;
    margin-bottom: 15px;
  }
`

export const SelectArrow = styled.div`
  position: absolute;
  top: ${({$small}) => ($small ? -7 : -12)}px;
  right: -10px;
  background: #d8d8d8;
  height: ${({$small}) => ($small ? 24 : 34)}px;
  width: ${({$small}) => ($small ? 25 : 34)}px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;

  ::after {
    content: '';
    position: absolute;
    right: ${({$small}) => ($small ? 6 : 10)}px;
    top: ${({$small}) => ($small ? 8 : 12)}px;
    width: 0;
    height: 0;
    border-left: ${({$small}) => ($small ? 5 : 6)}px solid transparent;
    border-right: ${({$small}) => ($small ? 5 : 6)}px solid transparent;
    border-top: ${({$small}) => ($small ? 7 : 10)}px solid white;
    clear: both;
    transition: all 0.5s ease;
  }
`

export const SelectInputStyled = styled(({hasError, ...rest}) => (
  <Select {...rest} />
))`
  width: 100%;
  height: ${({$small}) => ($small ? 26 : 36)}px;
  overflow: hidden;

  max-width: ${({$small, $adjustable}) =>
    $adjustable ? '100%' : $small ? '200px' : '100%'};

  &&& {
    &.ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      color: rgba(0, 0, 0, 0.85);
      background: #f5f5f5;
      cursor: not-allowed;
    }

    .ant-select-selector {
      width: 100%;
      padding: ${({$small}) => ($small ? '2px 8px' : '5px 11px')};
      height: ${({$small}) => ($small ? 26 : 36)}px;
      border-radius: 4px;
      border: 1px solid
        ${({hasError}) => (hasError ? ErrorColor : BorderColor)};
      outline: none;
      box-shadow: none;
      opacity: ${({disabled}) => (disabled ? 0.5 : 1)};

      &:hover,
      &:focus,
      &:visited,
      &:active {
        border: 1px solid
          ${({disabled}) => (disabled ? BorderColor : PrimaryButtonColor)};
        outline: none;
        box-shadow: none;
      }
    }

    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      line-height: ${({$small}) => ($small ? 20 : 26)}px;
    }

    .ant-select-selection-search-input {
      height: ${({$small}) => ($small ? 18 : 30)}px;
    }

    .ant-select-selection-search {
      left: ${({$small}) => ($small ? 8 : 11)}px;
    }

    .ant-select-clear {
      background: #d8d8d8;
      right: ${({$small}) => ($small ? 6 : 11)}px;
    }
  }
`

export const SelectLoading = styled(({small}) => (
  <SelectRightSection $small={small}>
    <LoadingOutlined />
  </SelectRightSection>
))``

export const SelectOption = styled(Option)``

const SelectRightSection = styled.div`
  position: absolute;
  top: ${({$small}) => ($small ? -6 : -12)}px;
  right: -10px;
  background: #d8d8d8;
  height: ${({$small}) => ($small ? 22 : 34)}px;
  width: ${({$small}) => ($small ? 22 : 34)}px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 2;
  font-size: 16px;
`
