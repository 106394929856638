import React from 'react'

import {PlusOutlined} from '@ant-design/icons'
import PropTypes from 'prop-types'

import {getFromStorage, STORAGE_KEYS} from '../../utils/storage'
import {
  SecondarySelect,
  SortBySelectContainer,
  StyledOption
} from './InputStyles'

const SortBySelect = ({options, onChange}) => {
  const defaultValue = getFromStorage(STORAGE_KEYS.ORDER_SORT_VALUE)

  return (
    <SortBySelectContainer>
      <SecondarySelect
        defaultValue={defaultValue}
        onChange={onChange}
        placeholder='Sort by'
        suffixIcon={<PlusOutlined />}
      >
        {options.map((option, index) => (
          <StyledOption key={index} value={option.value}>
            {option.name}
          </StyledOption>
        ))}
      </SecondarySelect>
    </SortBySelectContainer>
  )
}

SortBySelect.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
}

export default SortBySelect
