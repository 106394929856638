/* eslint-disable no-template-curly-in-string */

export default {
  OOPS_PAGE_NOT_FOUND: 'Oups... Page introuvable!',
  PAGE_NOT_FOUND_DESCRIPTION_1: 'Nous nous excusons pour le dérangement.',
  PAGE_NOT_FOUND_DESCRIPTION_2:
    "Apparemment, la page que vous essayez de trouver a été effacée ou n'a jamais existé.",
  BACK_TO_INITIAL_PAGE: 'Retour à la page initiale',
  PASSWORD_DOESNT_MATCH: 'Le mot de passe ne correspond pas',
  OUTFIT: 'ARTICLE',
  CREDITS: 'REVENDICATION',
  FITTING: 'TAILLE ÉVALUATION',
  MY_ORDERS: 'COMMANDES',
  ADDRESSES: 'ADDRESSES',
  SUPPORT: 'SUPPORT',
  ACCOUNT_SETTINGS: 'PARAMÈTRES DU COMPTE',
  MY_BAG: 'Mon sac',
  OUTFIT_TITLE: 'Chaussures de travail',
  OUTFIT_SUBTITLE:
    'Ajoutez à votre panier tous les articles dont vous avez besoin pour compléter votre.',
  CREDIT_TITLE: 'Revendication',
  CREDIT_SUBTITLE: 'Vérifiez votre solde par article',
  ORDERS_TITLE: 'Commandes',
  ORDERS_SUBTITLE: 'commandes trouvées',
  SUPPORT_TITLE: 'FAQ et Support',
  SUPPORT_SUBTITLE: '',
  FITTING_TITLE: 'Taille Évaluation',
  FITTING_SUBTITLE: 'Vérifiez les mesures des uniformes',
  ADDRESSES_TITLE: "Carnet d'adresses",
  ADDRESSES_SUBTITLE: 'Gérez vos adresses',
  PERSONAL_DATA: 'Données personnelles',
  LOGIN: "S'identifier",
  SIGNUP_NEWSLETTER: "Je veux m'inscrire à la newsletter Skypro",
  NO_DATA_TO_DISPLAY: 'No data to Display!',
  REVOKE_ACCESS:
    "Révoquer l'accès à mes données donné sous le consentement de la politique de confidentialité de SKYPRO (cette opération vous déconnectera et empêchera l'utilisation du portail mySkypro)",
  ORDER_ALL_OUTFIT: 'COMMANDER TOUTES LES TENUES',
  SAVE: 'SAUVEGARDER',
  EDIT: 'MODIFIER',
  ADD_NEW_ADDRESS: 'AJOUTER UNE NOUVELLE ADRESSE',
  CLOSE: 'FERMER',
  PRIMARY_ADDRESS: 'Adresse primaire',
  LOGOUT: 'DÉCONNEXION',
  EMAIL_USERNAME: "Email / Nom d'utilisateur",
  CURRENT_PASSWORD: 'Mot de passe actuel',
  NEW_PASSWORD: 'Mot de passe',
  CONFIRM_PASSWORD: 'Confirmez le mot de passe',
  ADDRESS_NAME: "Nom de l'adresse",
  NAME_FOR_DELIVERY: 'Nom pour la livraison',
  ADDRESS_LINE_1: "Ligne d'adresse 1",
  ADDRESS_LINE_2: "Ligne d'adresse 2",
  ADDRESS_LINE_3: "Ligne d'adresse 3",
  STREET_NUMBER: 'Numéro de rue',
  ZIP_CODE: 'Code postal',
  CITY: 'Ville',
  REGION: 'Région',
  COUNTRY: 'Pays',
  PHONE_NUMBER: 'Numéro de téléphone',
  VAT_NUMBER: 'Numéro de TVA',
  ADD_NEW_ADDRESS_LOWER: 'Ajouter une nouvelle adresse',
  USED_CREDITS: 'CRÉDITS USAGÉ',
  ITEMS_IN_OUTFIT: 'Articles dans votre tenue',
  OTHER_ITEMS_FOR_YOU: "D'autres articles pour vous",
  OTHER_ITEMS_DESCRIPTION:
    "Nous avons trouvé d'autres articles qui pourraient compléter votre uniforme.",
  WELCOME_LOGIN: 'Bienvenue sur le',
  WELCOME_SUB:
    'Gagnez en efficacité tout en responsabilisant votre équipe',
  WELCOME_SUB_DEFAULT:
    "Un mélange unique de savoir-faire et de technologie qui crée les meilleures solutions de soins d'équipage au monde pour votre entreprise",
  SIGN_IN: 'Connexion',
  RECOVER: 'Récupérer',
  EMAIL_LOGIN: 'Email',
  USERNAME: "Nom d'utilisateur",
  PASSWORD_LOGIN: 'Mot de passe',
  LOG_IN: 'Se connecter',
  REMEMBER_ME: 'Se souvenir de moi',
  RECOVER_PASSWORD: 'Mot de passe oublié?',
  ACCOUNT: 'compte',
  ORDERS: 'commandes',
  FITTING_SMALL: 'essayage',
  HOW_CHANGE_PASSWORD: 'Comment est-ce que je change mon mot de passe?',
  HOW_CHANGE_PASSWORD_ANSWER:
    'Cliquez sur votre nom dans le coin supérieur droit ou <a href="/accountsettings">ici</a> pour accéder aux définitions de votre compte, où vous pouvez modifier votre mot de passe pour vous connecter à votre compte.',
  HOW_CHECK_CREDITS: 'Comment vérifier mes crédits ?',
  HOW_CHECK_CREDITS_ANSWER:
    'Vous pouvez vérifier vos crédits sur <a href="/credits">Crédits</a>. Vous y trouverez une liste de tous les articles de votre uniforme et leurs crédits restants et totaux, ainsi que leur date de renouvellement, le cas échéant.',
  HOW_CHANGE_ADDRESSES:
    'Comment puis-je modifier/ajouter des adresses et des contacts ?',
  HOW_CHANGE_ADDRESSES_ANSWER:
    'Accédez à <a href="/addresses">Adresses</a> pour modifier/ajouter des adresses et des contacts. Cliquez sur « Modifier » pour modifier les données ou sur « Ajouter une nouvelle adresse » pour inclure une adresse supplémentaire. N\'oubliez pas que vous devez mettre à jour vos contacts avant de finaliser une commande.',
  HOW_CHECK_UNIFORM: 'Comment puis-je vérifier mon uniforme ?',
  HOW_CHECK_UNIFORM_ANSWER:
    'Vous pouvez vérifier vos articles d\'uniforme sur <a href="/outfit">Outfit</a>. Seuls les éléments qui composent votre uniforme sont affichés.',
  HOW_CHANGE_SIZE: 'Comment changer la taille de mon uniforme ?',
  HOW_CHANGE_SIZE_ANSWER:
    'Sur <a href="/measures">Mesures</a>, planifiez une nouvelle séance d\'essayage en cliquant sur "Reprogrammer" et en choisissant une date, une heure et un lieu.',
  HOW_SCHEDULE_FITTING: 'Comment planifier un essayage ?',
  HOW_SCHEDULE_FITTING_ANSWER:
    'Accédez à <a href="/measures">Mesures</a>, cliquez sur « Planifier » ou « Reprogrammer » et choisissez une date, une heure et un lieu.',
  HOW_CHECK_STATUS: "Comment puis-je vérifier l'état de ma commande ?",
  HOW_CHECK_STATUS_ANSWER:
    'Accédez à <a href="/myorders">Mes commandes</a>, recherchez votre numéro de commande et cliquez sur l\'icône "+" pour vérifier plus de détails sur votre commande.',
  RECEIVED_INCOMPLETE: "J'ai reçu une commande incomplète.",
  RECEIVED_INCOMPLETE_ANSWER:
    "Il est possible que votre commande ait été expédiée par colis. La commande sera finalisée dès que le stock sera disponible. Après l'expédition, vous recevrez un e-mail qui comprendra le(s) article(s) avec le numéro de suivi pour chaque livraison.",
  RECEIVED_INCORRECT: "J'ai reçu des articles incorrects.",
  RECEIVED_INCORRECT_ANSWER:
    "Vous avez reçu les articles que vous avez sélectionnés et signés au moment de l'essayage. S'il y a un mauvais article causé par nos services logistiques, veuillez contacter cs@wearskypro.com. \n\n Remarque : votre entreprise n'accepte pas les retours d'articles dans les tailles que vous avez sélectionnées.\n Remarque : l'expédition d'articles avec de nouvelles tailles pour des raisons d'échange entraîne des frais d'expédition que vous devrez payer.",
  PAYMENT_METHODS: 'Méthodes de payement.',
  PAYMENT_METHODS_ANSWER:
    "Nous acceptons les types de paiements suivants :\n VISA\n ATM\n Paypal\n MasterCard\n American Express\n\n Nous garantissons que votre paiement peut être effectué en toute sécurité conformément aux normes internationales de sécurité des paiements en ligne. \n Votre numéro de carte bancaire est inséré dans une page sécurisée, hébergée par notre banque, et donc externe à notre site. Aucun numéro de carte bancaire n'est enregistré ou visible sur notre site lors du processus de paiement. Nous n'avons pas accès à vos informations de paiement.\n Ce site Web utilise la technologie Secure Sockets Layer (SSL) pour offrir l'expérience d'achat la plus sécurisée possible. La technologie SSL permet le cryptage (codage) des informations confidentielles lors de vos transactions en ligne. Tous les formulaires de notre site sont protégés avec la même technologie SSL, afin que vos informations personnelles restent en sécurité.",
  REFUND_PERIOD: 'Période de remboursement.',
  REFUND_PERIOD_ANSWER:
    "Les remboursements s'effectuent via le même moyen de paiement que celui utilisé pour traiter la commande, dans un délai de 5 à 10 jours ouvrés, après réception du ou des articles retournés.\n\n Si vous payez avec une carte cadeau ou une carte de paiement ( virtuel ou physique), le remboursement sera crédité sur la même carte. Si au moment du remboursement vous n'avez plus avec vous la carte utilisée pour le paiement, nous vous suggérons de contacter notre service client à cs@wearskypro.com.",
  INCIDENT_UNIFORM:
    "Pendant que je travaillais, j'ai eu un incident avec mon uniforme. Puis-je l'échanger ?",
  INCIDENT_UNIFORM_ANSWER:
    'Allez dans <a href="/orders">Ordres</a>, "incidents uniformes" et expliquez la situation. Si votre réclamation est acceptée, une notification sera envoyée indiquant qu\'un nouvel article est disponible pour être envoyé pour remplacer celui endommagé.\n Si votre réclamation n\'est pas acceptée, une notification sera envoyée indiquant la raison.',
  GENERAL: 'Général',
  PAYMENTS: 'Paiements',
  RETURNS_EXCHANGES: 'Retours et échanges',
  HELPFUL: 'Utile',
  NOT_HELPFUL: 'Inutile',
  EXTRA_HELP: "Besoin d'une aide supplémentaire ?",
  DETAILS_HEADER: 'Détails',
  COMPOSITION: 'Composition',
  COMPOSITION_ANSWER: '50% coton, 47% polyamide, 3% élasthanne',
  WEIGHT: 'Poids',
  WEIGHT_ANSWER: '335grs',
  WIDTH: 'Largeur',
  WIDTH_ANSWER: '160CM',
  TEXTILE: 'Textile',
  TWILL: 'Sergé',
  TECH_SUBTITLE:
    'Protection et sécurité complètes pour votre vie professionnelle intense.',
  ABSOLUTE_COMFORT: 'Confort absolu',
  ABSOLUTE_COMFORT_TEXT:
    'Les semelles Skypro utilisent un matériau spécial à structure microcellulaire doté de caractéristiques étonnantes. Ils ne perdent pas leur amorti, sont respirants, doux et résistants, absorbent les impacts du sol, protégeant ainsi la colonne vertébrale et les articulations et offrent une plus grande flexibilité pour un confort absolu.',
  EASY_CLEAN: 'Nettoyage facile',
  EASY_CLEAN_TEXT:
    'En améliorant les caractéristiques répulsives de la surface de nos tissus, en utilisant une technologie haut de gamme soigneusement sélectionnée, permet une élimination facile des taches. Avec cette finition, les propriétés de séchage du tissu sont améliorées et nous garantissons un vêtement beaucoup plus durable.',
  ECOLOGIC: 'Écologique',
  ECOLOGIC_TEXT:
    "Garantir un minimum de déchets, d'emballages et d'empreinte environnementale conformément aux recommandations de l'ONU, en privilégiant le recyclage et les emballages réutilisés et en promouvant l'utilisation de matières premières durables, renouvelables et écologiques.",
  RELATED_TITLE: "D'autres articles pour vous",
  COLORS_TITLE: 'Couleurs disponibles',
  IMAGE: 'Image',
  TECHNOLOGY: 'Technologie',
  RELATED: 'En rapport',
  CHECKOUT: 'Checkout',
  CONFIRM_DETAILS: 'Confirmez vos coordonnées ci-dessous',
  CONTACT: 'Contact',
  SEND_TO: 'Envoyez à',
  PAYMENT_METHOD: 'Mode de paiement',
  SECURE_ENCRYPTED: 'Toutes les transactions sont sécurisées et cryptées',
  CHANGE: 'Changement',
  CREDIT_CARD: 'Carte de crédit',
  CREDIT_CARD_TEXT:
    'Lorsque vous cliquez sur Terminer la commande, vous serez redirigé vers la page de paiement',
  ATM: 'Référence Multibanco/ATM',
  ATM_TEXT: 'Vous recevrez un e-mail avec les détails du paiement',
  BILLING_DATA: 'Informations de facturation',
  INVOICE_TO: 'Facturer à',
  COMPLETE_ORDER: 'Complétez la commande',
  REFUND_POLICY: 'Politique de remboursement',
  PRIVACY_POLICY: 'Politique de confidentialité',
  TERMS_SERVICE: "Conditions d'utilisation",
  VOUCHER_CODE: "Code du bon d'achat",
  APPLY: 'Appliquer',
  SUBTOTAL: 'Total',
  TRANSPORTATION: 'Livraison',
  TOTAL: 'Totaliser',
  NO_ITEMS: "Vous n'avez aucun article dans votre panier.",
  SCROLL_DOWN: 'Défiler vers le bas',
  COLOR: 'COLEUR',
  STATUS: 'Statut',
  VALUE_TAG: 'Fermé',
  STATUS_TAG: 'Renouveler sur:',
  ORDER_BTN: 'Commander uniforme complet',
  FUNCTION: 'Fonction:',
  OUTFIT_ITEMS: 'Articles en tenue:',
  NEXT_FITTING: 'Prochain essayage:',
  RESCHEDULE: 'Reprogrammer',
  SCHEDULE: 'Horaire',
  ABOUT: 'A propos de Skypro',
  PROHEALTH: 'ProHealth Technology',
  CUSTOMER: 'Service clientèle',
  FOLLOW: 'Suivez-nous',
  NEWSLETTER: 'Newsletter',
  BLOG: 'Blog',
  COPYRIGHT: '©${date} SKYPRO. Tous droits réservés.',
  ORDER: 'Commande',
  DATE: 'Date',
  DELIVERY: 'Livraison',
  NO_ORDERS: 'Aucune commande trouvée',
  DELIVERY_ADDRESS: 'Adresse de livraison',
  PROBLEM_CONTACT: 'Un problème? Contactez-Nous',
  ORDER_SUMMARY: 'Résumé de la commande',
  ITEMS: 'Articles',
  POSTAGE_PACKING: 'Frais de port et emballage',
  ORDER_TOTAL: 'Commande totale',
  DATE_SUBMISSION: 'Date de soumission',
  ESTIMATED_DELIVERY: 'Livraison estimée',
  PAYMENT_STATE: 'Statut de paiement',
  GET_REFERENCE: 'Procéder au paiement',
  QUANTITY: 'Quantité',
  ADD_ADDRESS: 'Ajouter une nouvelle adresse',
  EDIT_ADDRESS: "Modifier l'adresse",
  SUBMIT: 'Soumettre',
  ADDRESS_BOOK: "Carnet d'adresses",
  MANAGE_ADDRESS: 'Gérez vos adresses',
  CITY_REQUIRED: 'La ville est un champ obligatoire',
  SCHEDULE_FITTING_TITLE: 'Ajustement des horaires',
  SCHEDULE_FITTING_SUBTITLE: 'Choisissez où vous souhaitez être mesuré',
  HELLO: 'Salut',
  CREDITS_LEFT: 'CRÉDITS GAUCHE',
  OF: 'SUR',
  RENEWS_ON: 'RENOUVELER SUR',
  DURATION: 'DURÉE',
  BACK_TO_CALENDAR: 'Retour au calendrier',
  COMPLETE_APPOINTMENT: 'Rendez-vous complet',
  EMPLOYEE: 'Employé',
  ITEMS_OUTFIT: 'Objets en tenue',
  LAST_FITTING: 'Dernier essayage',
  PREVIOUS: 'Précédent',
  NEXT: 'Suivant',
  CHOOSE_SIZE: 'Choisissez la taille ou numérisez automatiquement',
  DECLARE_MEASURES:
    'Je déclare par la présente que les mesures sont correctes',
  INSTRUCTIONS:
    'Suivez les instructions du <a href="../../files/fitting_guide.mp4" target="_blank" rel="noopener noreferrer">Tutoriel vidéo</a> puis consultez le <a href= "../../files/fitting_sizes_table.pdf" target="_blank" rel="noopener noreferrer">Tableau de référence</a>',
  UNIQUE_SIZE: 'TAILLE UNIQUE',
  SIZE: 'TAILLE: ',
  REF_FITTING: 'Réf Raccord',
  TELEPHONE: 'Telephone',
  HOUR: 'Heure',
  SELECT: 'Sélectionner',
  CHECKOUT_NOW: 'Commandez maintenant',
  CLEAR_BAG: 'Sac transparent',
  YOU_WILL_LIKE: 'Vous aimerez',
  MONTHS: 'mois',
  ADD_BAG: 'ajouter au panier',
  SELECT_QUESTION_TYPE:
    'Si vous préférez, sélectionnez le type de question et consultez notre FAQ :',
  FITTING_LOWERCASE: 'essayage',
  NOT_FITTED: 'PAS APPROPRIÉE',
  FITTED: 'AJUSTÉ',
  NA: 'NOM',
  SAVE_CHANGES: 'SAUVEGARDER LES MODIFICATIONS',
  BIRTH_DATE_OPTIONAL: 'Date de naissance (facultatif)',
  FIRST_NAME: 'Prénom',
  LAST_NAME: 'Nom de famille',
  SORT_BY: 'Trier Par',
  CART_CREDIT: 'crédit',
  CART_CREDITS: 'crédits',
  CART_ADD_ADDRESS: "Ajoutez l'adresse",
  CART_SHIPMENT_TEXT_1: 'Maximum de',
  CART_SHIPMENT_TEXT_2: 'expéditions par an.',
  CART_SHIPMENT_TEXT_3: 'expéditions encore disponibles.',
  PAYMENT_RECEIVED: 'Reçu',
  PAYMENT_PENDING: 'En attente',
  DOCUMENTS: 'Documents',
  NOTES: 'Billet',
  TRACKING: 'Suivi',
  PARTIALLY: 'Partiellement',
  COOKIES_WARNING:
    'Nous utilisons des cookies pour améliorer votre expérience utilisateur sur notre site. Si vous continuez à naviguer, nous supposerons que vous consentez à notre',
  COOKIES_WARNING_PRIVACY: 'les politiques de confidentialité',
  SINGLE_SIZE: 'TAILLE UNIQUE',
  CUSTOMER_SUPPORT_EMAIL: 'Email du support client :',
  CUSTOMER_SUPPORT_PHONE:
    'Pour le support client direct, utilisez le numéro de téléphone qui se trouve dans votre région.',
  CUSTOMER_SUPPORT_EUROPE: 'Portugal et pays européens',
  CUSTOMER_SUPPORT_USA: 'États-Unis',
  CUSTOMER_SUPPORT_UNITED_KINGDOM: 'Royaume-Uni',
  CUSTOMER_SUPPORT_CANADA: 'Canada',
  CUSTOMER_SUPPORT_OBS_1: "Coût d'appel vers national",
  CUSTOMER_SUPPORT_0BS_2: 'réseau fixe',
  NAME: 'Name',
  SUBJECT_PROBLEM_EMAIL: "J'ai un problème avec ma commande",
  ORDER_FOUND: ' commande trouvée',
  ORDERS_FOUND: ' commandes trouvés',
  PRODUCT: 'Produit',
  CHOOSE_YOUR_SIZE: 'CHOISISSEZ VOTRE TAILLE',
  RECOVER_SUCCESS:
    "Un nouveau mot de passe a été envoyé au courrier électronique de l'utilisateur",
  SIGN_UP: 'Nouveau sur le portail ?',
  SIGN_UP_LINK: "S'INSCRIRE",
  RETURN_TO_LOGIN_PAGE: 'Retour vers la page de connexion',
  ONBOARDING: 'Onboarding',
  ONBOARDING_CONTENT_TITLE:
    'Bienvenue sur le Portail des chaussures de travail',
  ONBOARDING_CONTENT_SUBTITLE:
    'Veuillez remplir les informations requises ci-dessous :',
  ACCEPT: 'Accepter',
  GENDER: 'Genre',
  DISTRICT: 'District',
  ADDRESS: 'Adresse',
  DAY: 'Jour',
  MONTH: 'Mois',
  YEAR: 'Année',
  JANUARY: 'Janvier',
  FEBRUARY: 'Février',
  MARCH: 'Mars',
  APRIL: 'Avril',
  MAY: 'Peut',
  JUNE: 'Juin',
  JULY: 'Juillet',
  AUGUST: 'Août',
  SEPTEMBER: 'Septembre',
  OCTOBER: 'Octobre',
  NOVEMBER: 'Novembre',
  DECEMBER: 'Décembre',
  ERROR: 'Erreur',
  ERROR_CURRENT_PASSWORD_REQUIRED:
    'Le mot de passe actuel est un champ obligatoire',
  ERROR_PASSWORD_CONFIRMATION_DOES_NOT_MATCH:
    'La confirmation du mot de passe ne correspond pas au mot de passe',
  ERROR_PASSWORD_EQUAL_PREVIOUS:
    'Le mot de passe actuel est égal au mot de passe précédent. Veuillez en utiliser un autre',
  ERROR_DATA_SAVED_FAILED:
    'Impossible de modifier les données, veuillez réessayer',
  SUCCESS: 'Succès',
  SUCCESS_DATA_SAVED_SUCCESSFULLY: 'Données mises à jour avec succès',
  AFGHANISTAN: 'Afghanistan',
  ALAND_ISLANDS: 'Iles Aland',
  ALBANIA: 'Albanie',
  ALGERIA: 'Algérie',
  AMERICAN_SAMOA: 'Samoa américaines',
  ANDORRA: 'Andorre',
  ANGOLA: 'Angola',
  ANGUILLA: 'Anguilla',
  ANTARCTICA: 'Antarctique',
  ANTIGUA_AND_BARBUDA: 'Antigua-et-Barbuda',
  ARGENTINA: 'Argentine',
  ARMENIA: 'Arménie',
  ARUBA: 'Aruba',
  AUSTRALIA: 'Australie',
  AUSTRIA: "L'Autriche",
  AZERBAIJAN: 'Azerbaïdjan',
  BAHAMAS: 'Bahamas',
  BAHRAIN: 'Bahreïn',
  BANGLADESH: 'Bangladesh',
  BARBADOS: 'Barbade',
  BELARUS: 'Biélorussie',
  BELGIUM: 'Belgique',
  BELIZE: 'Bélize',
  BENIN: 'Bénin',
  BERMUDA: 'Bermudes',
  BHUTAN: 'Bhoutan',
  BOLIVIA: 'Bolivie',
  BOSNIA_AND_HERZEGOVINA: 'Bosnie Herzégovine',
  BOTSWANA: 'Botswana',
  BOUVET_ISLAND: 'Île Bouvet',
  BRAZIL: 'Brésil',
  BRITISH_INDIAN_OCEAN_TERRITORY:
    "Territoire britannique de l'océan Indien",
  BRITISH_VIRGIN_ISLANDS: 'Îles Vierges britanniques',
  BRUNEI_DARUSSALAM: 'Brunei Darussalam',
  BULGARIA: 'Bulgarie',
  BURKINA_FASO: 'Burkina Faso',
  BURUNDI: 'Burundi',
  CAMBODIA: 'Cambodge',
  CAMEROON: 'Cameroun',
  CANADA: 'Canada',
  CANARY_ISLANDS: 'Îles Canaries',
  CAPE_VERDE: 'Cap Vert',
  CAYMAN_ISLANDS: 'Îles Caïmans',
  CENTRAL_AFRICAN_REPUBLIC: 'République centrafricaine',
  CHAD: 'Tchad',
  CHANNEL_ISLANDS: 'Îles anglo-normandes',
  CHILE: 'Chili',
  CHINA: 'Chine',
  CHRISTMAS_ISLAND: "L'île de noël",
  COCOS_KEELING_ISLANDS: 'Îles Cocos (Keeling)',
  COLOMBIA: 'Colombie',
  COMOROS: 'Comores',
  CONGO_KINSHASA: 'Congo (Kinshasa)',
  CONGO_BRAZZAVILLE: 'Congo-Brazzaville',
  COOK_ISLANDS: 'Îles Cook',
  COSTA_RICA: 'Costa Rica',
  CROATIA: 'Croatie',
  CUBA: 'Cuba',
  CYPRUS: 'Chypre',
  CZECHIA: 'Tchèque',
  DENMARK: 'Danemark',
  DJIBOUTI: 'Djibouti',
  DOMINICA: 'Dominique',
  DOMINICAN_REPUBLIC: 'République dominicaine',
  ECUADOR: 'Équateur',
  EGYPT: 'Egypte',
  EL_SALVADOR: 'Le Salvador',
  EQUATORIAL_GUINEA: 'Guinée Équatoriale',
  ERITREA: 'Érythrée',
  ESTONIA: 'Estonie',
  ETHIOPIA: 'Ethiopie',
  FALKLAND_ISLANDS_MALVINAS: 'Îles Falkland (Malvinas)',
  FAROE_ISLANDS: 'Îles Féroé',
  FIJI: 'Fidji',
  FINLAND: 'Finlande',
  FRANCE: 'France',
  FRENCH_GUIANA: 'Guyane Française',
  FRENCH_POLYNESIA: 'Polynésien français',
  FRENCH_SOUTHERN_TERRITORIES: 'Terres australes françaises',
  GABON: 'Gabon',
  GAMBIA: 'Gambie',
  GEORGIA: 'Géorgie',
  GERMANY: 'Allemagne',
  GHANA: 'Ghana',
  GIBRALTAR: 'Gibraltar',
  GREECE: 'Grèce',
  GREENLAND: 'Groenland',
  GRENADA: 'Grenade',
  GUADELOUPE: 'Guadeloupe',
  GUAM: 'Guam',
  GUATEMALA: 'Guatemala',
  GUERNSEY: 'Guernesey',
  GUINEA: 'Guinée',
  GUINEA_BISSAU: 'Guinée Bissau',
  GUYANA: 'Guyane',
  HAITI: 'Haïti',
  HEARD_AND_MCDONALD_ISLANDS: 'Îles Heard et McDonald',
  HONDURAS: 'Honduras',
  HONG_KONG: 'Hong Kong',
  HUNGARY: 'Hongrie',
  ICELAND: 'Islande',
  INDIA: 'Inde',
  INDONESIA: 'Indonésie',
  IRAN_ISLAMIC_REPUBLIC_OF: "Iran (République islamique d'Iran)",
  IRAQ: 'Irak',
  IRELAND: 'Irlande',
  ISLE_OF_MAN: 'Île de Man',
  ISRAEL: 'Israël',
  ITALY: 'Italie',
  IVORY_COAST: 'Costa do Marfim',
  JAMAICA: 'Jamaïque',
  JAPAN: 'Japon',
  JERSEY: 'Jersey',
  JORDAN: 'Jordan',
  KAZAKHSTAN: 'Kazakhstan',
  KENYA: 'Kenya',
  KIRIBATI: 'Kiribati',
  KOREA_NORTH: 'Corée du Nord',
  KOREA_SOUTH: 'Corée du Sud',
  KOSOVO: 'Kosovo',
  KUWAIT: 'Koweit',
  KYRGYZSTAN: 'Kirghizistan',
  LAO_PDR: 'République démocratique populaire lao',
  LATVIA: 'Lettonie',
  LEBANON: 'Liban',
  LESOTHO: 'Lesotho',
  LIBERIA: 'Libéria',
  LIBYA: 'Libye',
  LIECHTENSTEIN: 'Liechtenstein',
  LITHUANIA: 'Lituanie',
  LUXEMBOURG: 'Luxembourg',
  MACAO: 'Macao',
  MADAGASCAR: 'Madagascar',
  MALAWI: 'Malawi',
  MALAYSIA: 'Malaisie',
  MALDIVES: 'Maldives',
  MALI: 'Mali',
  MALTA: 'Malte',
  MARSHALL_ISLANDS: 'Iles Marshall',
  MARTINIQUE: 'Martinique',
  MAURITANIA: 'Mauritanie',
  MAURITIUS: 'Maurice',
  MAYOTTE: 'Mayotte',
  MEXICO: 'Mexique',
  MICRONESIA_FEDERATED_STATES_OF: 'Micronésie, États fédérés de',
  MOLDOVA: 'Moldavie',
  MONACO: 'Monaco',
  MONGOLIA: 'Mongolie',
  MONTENEGRO: 'Monténégro',
  MONTSERRAT: 'Montserrat',
  MOROCCO: 'Maroc',
  MOZAMBIQUE: 'Mozambique',
  MYANMAR: 'Birmanie',
  NAMIBIA: 'Namibie',
  NAURU: 'Nauru',
  NEPAL: 'Népal',
  NETHERLANDS: 'Pays-Bas',
  NETHERLANDS_ANTILLES: 'Antilles néerlandaises',
  NEW_CALEDONIA: 'Nouvelle Calédonie',
  NEW_ZEALAND: 'Nouvelle-Zélande',
  NICARAGUA: 'Nicaragua',
  NIGER: 'Niger',
  NIGERIA: 'Nigeria',
  NIUE: 'Nioué',
  NORFOLK_ISLAND: "l'ile de Norfolk",
  NORTH_MACEDONIA: 'Macédoine du Nord',
  NORTHERN_MARIANA_ISLANDS: 'Îles Mariannes du Nord',
  NORWAY: 'Norvège',
  OMAN: 'Oman',
  PAKISTAN: 'Pakistan',
  PALAU: 'Palaos',
  PALESTINIAN_TERRITORY: 'Territoire Palestinien',
  PANAMA: 'Panama',
  PAPUA_NEW_GUINEA: 'Papouasie Nouvelle Guinée',
  PARAGUAY: 'Paraguay',
  PERU: 'Pérou',
  PHILIPPINES: 'Philippines',
  PITCAIRN: 'Îles Picarnia',
  POLAND: 'Pologne',
  PORTUGAL: 'Portugal',
  PUERTO_RICO: 'Porto Rico',
  QATAR: 'Qatar',
  REUNION_ISLAND: 'Ile de la Réunion',
  ROMANIA: 'Roumanie',
  RUSSIAN_FEDERATION: 'Fédération Russe',
  RWANDA: 'Rwanda',
  SAINT_BARTHOLOMEW: 'Saint Barthélemy',
  SAINT_HELENA: 'Sainte-Hélène',
  SAINT_KITTS_AND_NEVIS: 'Saint-Christophe-et-Niévès',
  SAINT_LUCIA: 'Sainte-Lucie',
  SAINT_PIERRE_AND_MIQUELON: 'Saint-Pierre-et-Miquelon',
  SAINT_VINCENT_AND_GRENADINES: 'Saint-Vincent-et-les-Grenadines',
  SAINT_MARTIN_FRENCH_PART: 'Saint-Martin (partie française)',
  SAMOA: 'Samoa',
  SAN_MARINO: 'Saint Marin',
  SAO_TOME_AND_PRINCIPE: 'Sao Tomé et Principe',
  SAUDI_ARABIA: 'Arabie Saoudite',
  SENEGAL: 'Sénégal',
  SERBIA: 'Serbie',
  SEYCHELLES: 'Seychelles',
  SIERRA_LEONE: 'Sierra Leone',
  SINGAPORE: 'Singapour',
  SLOVAKIA: 'Slovaquie',
  SLOVENIA: 'Slovénie',
  SOLOMON_ISLANDS: 'Îles Salomon',
  SOMALIA: 'Somalie',
  SOUTH_AFRICA: 'Afrique du Sud',
  SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS:
    'Géorgie du Sud et îles Sandwich du Sud',
  SOUTH_SUDAN: 'Sud-Soudan',
  SPAIN: 'Espagne',
  SRI_LANKA: 'Sri Lanka',
  SUDAN: 'Soudan',
  SURINAME: 'Surinam',
  SVALBARD_AND_JAN_MAYEN_ISLANDS: 'Îles Svalbard et Jan Mayen',
  SWAZILAND: 'Swaziland',
  SWEDEN: 'Suède',
  SWITZERLAND: 'Suisse',
  SYRIAN_ARAB_REPUBLIC_SYRIA: 'République arabe syrienne (Syrie)',
  TAIWAN_REPUBLIC_OF_CHINA: 'Taïwan, République de Chine',
  TAJIKISTAN: 'Tadjikistan',
  TANZANIA_UNITED_REPUBLIC_OF: 'Tanzanie, République-Unie de',
  THAILAND: 'Thaïlande',
  TIMOR_LESTE: 'Timor oriental',
  TOGO: 'Aller',
  TOKELAU: 'Tokélaou',
  TONGA: 'Tonga',
  TRINIDAD_AND_TOBAGO: 'Trinité-et-Tobago',
  TUNISIA: 'Tunisie',
  TURKEY: 'Turquie',
  TURKMENISTAN: 'Turkménistan',
  TURKS_AND_CAICOS_ISLANDS: 'Îles Turques-et-Caïques',
  TUVALU: 'Tuvalu',
  UGANDA: 'Ouganda',
  UKRAINE: 'Ukraine',
  UNITED_ARAB_EMIRATES: 'Emirats Arabes Unis',
  UNITED_KINGDOM: 'ROYAUME-UNI',
  UNITED_STATES_OF_AMERICA: 'Etats-Unis',
  URUGUAY: 'Uruguay',
  US_MINOR_OUTLYING_ISLANDS: 'Îles mineures éloignées des États-Unis',
  UZBEKISTAN: 'Ouzbékistan',
  VANUATU: 'Vanuatu',
  VATICAN_CITY: 'Cité du Vatican',
  VENEZUELA_BOLIVARIAN_REPUBLIC: 'Venezuela (République bolivarienne)',
  VIETNAM: 'Viêt Nam',
  VIRGIN_ISLANDS_US: 'Îles Vierges, États-Unis',
  WALLIS_AND_FUTUNA_ISLANDS: 'Îles Wallis et Futuna',
  WESTERN_SAHARA: 'Sahara occidental',
  YEMEN: 'Yémen',
  ZAMBIA: 'Zambie',
  ZIMBABWE: 'Zimbabwe',
  SUCCESS_ADDRESS_SAVED_SUCCESSFULLY: 'Adresse enregistrée avec succès',
  ORDER_REQUEST_WAS_COMPLETED:
    'Votre commande a été complétée avec succès',
  ORDER_REQUEST_WAS_RECEIVED: 'Votre demande de commande a été reçue',
  ORDER_NUMBER: 'Numéro de commande',
  ORDER_COMPLETED_DETAILS_1:
    "Un email avec les détails de votre commande a été envoyé à l'adresse email que vous avez fournie.",
  ORDER_COMPLETED_DETAILS_2: 'Veuillez le conserver pour vos dossiers.',
  ORDER_COMPLETED_DETAILS_3:
    "Vous pouvez visiter la page Mes Commandes à tout moment pour vérifier l'état de votre commande.",
  ORDER_RECEIVED_DETAILS_1:
    "Les détails de la commande ont été envoyés à l'adresse e-mail fournie.",
  ORDER_RECEIVED_DETAILS_2:
    "Maintenant, veuillez attendre l'approbation de votre entreprise.",
  ORDER_RECEIVED_DETAILS_3:
    'Vous pouvez vérifier l\'avancement à tout moment en vous rendant dans la rubrique "Mes commandes".',
  CONTINUE_SHOPPING: 'Continuer les achats',
  BUTTON_MY_ORDERS: 'Mes commandes',
  THANK_YOU: 'Merci',
  FITTING_SCHEDULE_NON_EXISTANT:
    "Malheureusement, il n'y a pas de créneaux disponibles pour planifier votre séance d'essayage. Veuillez contacter votre gestionnaire.",
  PROCEED_TO_MEASURES: 'Passer aux mesures',
  LAYOUT_VIEW: 'Mise en page',
  IMAGE_VIEW: "Vue d'image",
  CHECK_TERMS_SERVICE:
    'Vous devez déclarer que les mesures sont correctes',
  REJECT: 'Rejeter',
  PRIVACY_POLICY_CHECK:
    'Je déclare avoir lu et reconnu la politique de confidentialité de SKYPRO',
  PRIVACY_POLICY_CHECK_UNVALID:
    'Vous devez accepter la politique de confidentialité de SKYPRO pour pouvoir utiliser ce portail',
  NO_MORE_STOCK_AVAILABLE: 'Plus de stock disponible',
  ORDER_LIMIT_REACHED:
    'Vous avez atteint la limite de commande pour cet article'
}
