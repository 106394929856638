import React, {useEffect, useState} from 'react'

import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect, useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

import Maestro from '../../assets/Payments/maestro.png'
import Mastercard from '../../assets/Payments/master-card-dark.png'
import Multibanco from '../../assets/Payments/multibanco.png'
import Paypal from '../../assets/Payments/paypal.png'
import Payshop from '../../assets/Payments/payshop.png'
import Visa from '../../assets/Payments/visa-dark.png'
import {
  GetAllCheckoutAddresses,
  GetAllCountries
} from '../../infra/requests/AddressRequests'
import {
  GetEmployeeShipment,
  DoCreditCheckout,
  GetPaymentMethodsByCountry
} from '../../infra/requests/CheckoutRequests'
import Currency from '../../infra/utils/Currency'
import {bagDelete} from '../../redux/data/bag/BagActions'
import GetImage from '../../shared/components/Image'
import BaseLoading from '../../shared/components/loading/BaseLoading'
import {
  AddressList,
  Article,
  AsideArticle,
  BillingRow,
  BillingTitle,
  CartAside,
  CartContainer,
  CartWrapper,
  ChangeLink,
  CollapsePanel,
  ConcludeBtn,
  CostsRow,
  CostsText,
  Fieldset,
  FirstPanel,
  HeadingSection,
  HeadingSpan,
  HeadingTitle,
  HeadingWrapper,
  Info,
  InfoList,
  InfoShipping,
  InfoText,
  InfoTextContact,
  InfoTextSpan,
  PaymentCollapse,
  PaymentContainer,
  PaymentInfo,
  PaymentMethods,
  PaymentMethodsContainer,
  PaymentTitle,
  RadioButton,
  RowContainer,
  RowDiv,
  RowInfo,
  RowTotal,
  SelectLink,
  ShipContainer,
  ShippingAddress,
  ShippingLabel,
  TextContainer,
  TotalText,
  Utilities,
  Utility,
  UtilityLink
} from './CheckoutStyles'
import CheckoutBagItem from './components/CheckoutBagItem'

const Checkout = ({user, activeLanguage, translate}) => {
  const history = useHistory()
  const [employeeShipment, setEmployeeShipment] = useState()
  const [loading, setLoading] = useState(true)
  const [address, setAddress] = useState([])
  const [countryList, setCountryList] = useState()
  const [contact, setContact] = useState()
  const [shippingAddress, setShippingAddress] = useState()
  const [paymentMethods, setPaymentMethods] = useState(null)
  const [billingAddress, setBillingAddress] = useState()
  const [changeAddress, setChangeAddress] = useState(0)
  const [isHover, setIsHover] = useState(false)

  const dispatch = useDispatch()

  const {bagItems, bagTotal} = useSelector((state) => state.bag)

  const handleMouseEnter = () => {
    setIsHover(true)
  }

  const handleMouseLeave = () => {
    setIsHover(false)
  }

  const btnStyle = {
    transition: 'all 0.5s ease',
    color: isHover
      ? user.configuration?.layoutColors?.contentButtonText
      : user.configuration?.layoutColors?.contentText,
    border: `1px solid ${user.configuration?.layoutColors?.contentText}`,
    backgroundColor: isHover
      ? user.configuration?.layoutColors?.contentText
      : 'transparent'
  }

  useEffect(() => {
    try {
      // const GetPaymentMethods = async () => {
      //   const {data, success} = await GetAllCheckoutAddresses()

      //   if (success) {
      //     setContact(data)
      //     setAddress(data.contactDetail)
      //     setShippingAddress(data.contactDetail[0])
      //     setBillingAddress(data.contactDetail[0])
      //   }
      // }

      const GetPaymentMethods = async () => {
        const {data, success} = await GetAllCheckoutAddresses()

        if (success) {
          const {countryId} = data?.contactDetail[0]
          const result = await GetPaymentMethodsByCountry(countryId)
          setContact(data)

          if (result.success) {
            setPaymentMethods(result.data)
            setAddress(
              data.contactDetail.sort(
                (a, b) =>
                  new Date(Date.parse(b.dateCreated)) -
                  new Date(Date.parse(a.dateCreated))
              ) || []
            )
            setShippingAddress(data.contactDetail[0])
            setBillingAddress(data.contactDetail[0])
          }
        }
      }

      const GetCountries = async () => {
        const {data, success} = await GetAllCountries()

        if (success) {
          setCountryList(data)
        }
      }

      const GetEmployeeShipments = async () => {
        const {data, success} = await GetEmployeeShipment()

        if (success) {
          setEmployeeShipment(data)
        }
      }
      GetPaymentMethods()
      GetCountries()
      GetEmployeeShipments()
      setLoading(false)
    } catch (e) {
      console.error(e)
    }
  }, [])

  const firstPanelImages = () => (
    <>
      <FirstPanel src={Visa} alt='' />
      <FirstPanel src={Mastercard} alt='' />
      <FirstPanel src={Maestro} alt='' />
      <FirstPanel src={Payshop} alt='' />
    </>
  )

  const secondPanelImage = () => <FirstPanel src={Multibanco} alt='' />

  const customIcon = (props) => {
    const {isActive} = props
    if (isActive) {
      return (
        <>
          <RadioButton checked />
        </>
      )
    }
    return <RadioButton checked={false} />
  }

  // FIXME add shippingCosts below to checkout Total
  const checkoutTotal = bagTotal

  const ChangeShippingAddress = (newShippingAddress) => {
    setShippingAddress(newShippingAddress)
    setChangeAddress(0)
  }

  const ChangeBillingAddress = (newAddress) => {
    setBillingAddress(newAddress)
    setChangeAddress(0)
  }

  async function handleCheckout() {
    const values = {
      bagItems,
      billingAddressId: shippingAddress?.contactDetailId,
      shippingAddressId: shippingAddress?.contactDetailId,
      languageId: user.languageId
    }

    const {data, success} = await DoCreditCheckout(values)

    if (success) {
      dispatch(bagDelete())
      history.push(`/thankyou/${data}`)
      dispatch(bagDelete())
    }
  }

  if (loading) {
    return <BaseLoading />
  }

  return (
    <div>
      <HeadingSection
        style={{
          backgroundColor: user.configuration?.layoutColors?.secondary,
          color: user.configuration?.layoutColors?.headerText
        }}
      >
        <HeadingWrapper>
          <Article>
            <HeadingTitle>
              <Translate id='CHECKOUT' />
            </HeadingTitle>
            <HeadingSpan>
              <Translate id='CONFIRM_DETAILS' />
            </HeadingSpan>
          </Article>
        </HeadingWrapper>
      </HeadingSection>
      <CartContainer>
        <CartWrapper>
          <CartAside>
            <PaymentContainer>
              <InfoList>
                <Info>
                  <InfoText>
                    <InfoTextSpan>
                      <Translate id='CONTACT' />
                    </InfoTextSpan>
                    <InfoTextContact>
                      {user.loweredUserName}
                    </InfoTextContact>
                  </InfoText>
                </Info>
                <Info>
                  <InfoText>
                    <InfoTextSpan>
                      <Translate id='SEND_TO' />
                    </InfoTextSpan>
                    <ShipContainer>
                      <ShippingLabel>
                        {shippingAddress?.label}
                      </ShippingLabel>
                      <ShippingAddress>
                        {shippingAddress?.name} <br />
                        {shippingAddress?.addressLine1}{' '}
                        {shippingAddress?.addressLine2},<br />
                        {shippingAddress?.zipCode} {shippingAddress?.city},{' '}
                        {shippingAddress?.region},{' '}
                        {shippingAddress?.country?.name}
                      </ShippingAddress>
                    </ShipContainer>
                    {contact?.contactTypeId === 1 ? (
                      <ChangeLink
                        onClick={() => history.push('/addresses')}
                      >
                        <Translate id='CART_ADD_ADDRESS' />
                      </ChangeLink>
                    ) : contact?.contactTypeId === 2 ? (
                      <SelectLink
                        onClick={() =>
                          setChangeAddress(changeAddress === 1 ? 0 : 1)
                        }
                      >
                        <Translate id='CHANGE' />
                      </SelectLink>
                    ) : null}
                  </InfoText>

                  {changeAddress === 1 &&
                    address
                      .filter((product) => product !== shippingAddress)
                      .map((addressItem) => {
                        const {
                          contactDetailId,
                          label,
                          name,
                          addressLine1,
                          zipCode,
                          city,
                          region,
                          countryId
                        } = addressItem
                        return (
                          <AddressList key={contactDetailId}>
                            <InfoTextSpan />
                            <ShipContainer>
                              <ShippingLabel>{label}</ShippingLabel>
                              <ShippingAddress>
                                {name} <br /> {addressLine1}, {zipCode}{' '}
                                {city}, {region},{' '}
                                {
                                  countryList?.find(
                                    (x) => x.CountryId === countryId
                                  )?.Name
                                }
                              </ShippingAddress>
                            </ShipContainer>
                            <SelectLink
                              onClick={() =>
                                ChangeShippingAddress(addressItem)
                              }
                            >
                              <Translate id='SELECT' />
                            </SelectLink>
                          </AddressList>
                        )
                      })}
                </Info>
              </InfoList>
              <PaymentMethods>
                <Fieldset>
                  <RowContainer>
                    <PaymentTitle>
                      <Translate id='PAYMENT_METHOD' />
                    </PaymentTitle>
                    <PaymentInfo>
                      <Translate id='SECURE_ENCRYPTED' />
                    </PaymentInfo>
                  </RowContainer>
                  <PaymentMethodsContainer>
                    <PaymentCollapse
                      accordion
                      defaultActiveKey={1}
                      expandIcon={customIcon}
                    >
                      {/* Logic required for when Payment process is developed */}
                      {/* {paymentMethods?.map((method) => (
                        <CollapsePanel
                          header={method?.paymentType?.name}
                          key={method?.paymentType?.paymentMethod}
                          extra={GetImage(
                            method?.paymentType?.iconFilename
                          )}
                        >
                          {method?.paymentType?.paymentMethod === 2 ? (
                            <Translate id='ATM_TEXT' />
                          ) : method?.paymentType?.paymentMethod === 1 ? (
                            <Translate id='CREDIT_CARD_TEXT' />
                          ) : (
                            ''
                          )}
                        </CollapsePanel>
                      ))} */}
                      <CollapsePanel
                        header={translate('CREDIT_CARD')}
                        key='1'
                        extra={firstPanelImages()}
                      >
                        <Translate id='CREDIT_CARD_TEXT' />
                      </CollapsePanel>
                      <CollapsePanel
                        header={translate('ATM')}
                        extra={secondPanelImage()}
                        key='2'
                      >
                        <Translate id='ATM_TEXT' />
                      </CollapsePanel>
                      <CollapsePanel
                        header={
                          <img
                            src={Paypal}
                            style={{width: '54px'}}
                            alt=''
                          />
                        }
                        key='3'
                      >
                        <Translate id='CREDIT_CARD_TEXT' />
                      </CollapsePanel>
                    </PaymentCollapse>
                  </PaymentMethodsContainer>
                </Fieldset>
                <Fieldset>
                  <BillingRow>
                    <BillingTitle>
                      <Translate id='BILLING_DATA' />
                    </BillingTitle>
                  </BillingRow>
                  <BillingRow>
                    <InfoList>
                      <InfoShipping>
                        <TextContainer>
                          <InfoTextSpan>
                            <Translate id='INVOICE_TO' />
                          </InfoTextSpan>
                          <ShipContainer>
                            <ShippingLabel>
                              {billingAddress?.name}
                            </ShippingLabel>
                            <ShippingAddress>
                              {billingAddress?.name} <br />{' '}
                              {billingAddress?.addressLine1},{' '}
                              {billingAddress?.zipCode}{' '}
                              {billingAddress?.city},{' '}
                              {billingAddress?.region},{' '}
                              {
                                countryList?.find(
                                  (x) => x.CountryId === address?.countryId
                                )?.Name
                              }
                            </ShippingAddress>
                          </ShipContainer>
                          <SelectLink onClick={() => setChangeAddress(2)}>
                            <Translate id='CART_ADD_ADDRESS' />
                          </SelectLink>
                        </TextContainer>
                        {changeAddress === 2 &&
                          address
                            .filter(
                              (product) => product !== billingAddress
                            )
                            .map((address) => (
                              <AddressList>
                                <InfoTextSpan />
                                <ShipContainer>
                                  <ShippingLabel>
                                    {address?.label}
                                  </ShippingLabel>
                                  <ShippingAddress>
                                    {address?.name} <br />{' '}
                                    {address?.addressLine1},{' '}
                                    {address?.zipCode} {address?.city},{' '}
                                    {address?.region},{' '}
                                    {
                                      countryList?.find(
                                        (x) =>
                                          x.CountryId ===
                                          address?.countryId
                                      )?.Name
                                    }
                                  </ShippingAddress>
                                </ShipContainer>
                                <SelectLink
                                  onClick={() =>
                                    ChangeBillingAddress(address)
                                  }
                                >
                                  <Translate id='SELECT' />
                                </SelectLink>
                              </AddressList>
                            ))}
                      </InfoShipping>
                    </InfoList>
                  </BillingRow>
                </Fieldset>
                <RowContainer>
                  <RowDiv>
                    <ConcludeBtn
                      style={btnStyle}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      onClick={() => handleCheckout()}
                      disabled={bagItems.length === 0}
                    >
                      <Translate id='COMPLETE_ORDER' />
                    </ConcludeBtn>

                    <Utilities>
                      <Utility>
                        <Translate id='CART_SHIPMENT_TEXT_1' />{' '}
                        {employeeShipment?.orderQuantity}{' '}
                        <Translate id='CART_SHIPMENT_TEXT_2' />{' '}
                        {
                          employeeShipment?.ordersAvailableWithinRenewalPeriod
                        }{' '}
                        <Translate id='CART_SHIPMENT_TEXT_3' />
                      </Utility>
                    </Utilities>
                  </RowDiv>
                </RowContainer>
              </PaymentMethods>
            </PaymentContainer>
            <Utilities>
              <Utility>
                <UtilityLink
                  href='https://wearskypro.com/Corp/DeliveriesReturns.aspx'
                  target='_blank'
                >
                  <Translate id='REFUND_POLICY' />
                </UtilityLink>
              </Utility>
              <Utility>
                <UtilityLink
                  href='https://wearskypro.com/Corp/PrivacyPolicy.aspx'
                  target='_blank'
                >
                  <Translate id='PRIVACY_POLICY' />
                </UtilityLink>
              </Utility>
              <Utility>
                <UtilityLink
                  href='https://wearskypro.com/Corp/DeliveriesReturns.aspx#payments'
                  target='_blank'
                >
                  <Translate id='TERMS_SERVICE' />
                </UtilityLink>
              </Utility>
            </Utilities>
          </CartAside>
          <CartAside>
            {bagItems?.map((product, index) => {
              const checkoutBagItemProps = {
                product,
                index,
                user,
                activeLanguage
              }
              return (
                <CheckoutBagItem {...checkoutBagItemProps} key={index} />
              )
            })}
            <AsideArticle>
              {/* <RowInfo>
                <Form>
                  <ApplyCodeContainer>
                    <InputWrapper>
                      <CodeInput placeholder={translate('VOUCHER_CODE')} />
                    </InputWrapper>
                  </ApplyCodeContainer>
                  <ApplyBtn>
                    <Translate id='APPLY' />
                  </ApplyBtn>
                </Form>
              </RowInfo> */}
              <RowInfo>
                <CostsRow>
                  <CostsText>
                    <Translate id='SUBTOTAL' />
                  </CostsText>
                  <CostsText>
                    {`${Currency.format(bagTotal, user.currencyCode)}`}
                  </CostsText>
                </CostsRow>
                <CostsRow>
                  <CostsText>
                    <Translate id='TRANSPORTATION' />
                  </CostsText>
                  <CostsText>
                    {`${Currency.format(0, user.currencyCode)}`}
                  </CostsText>
                </CostsRow>
              </RowInfo>
              <RowInfo>
                <RowTotal>
                  <TotalText>
                    <Translate id='TOTAL' />
                  </TotalText>
                  <TotalText>
                    {`${Currency.format(
                      checkoutTotal,
                      user.currencyCode
                    )}`}
                  </TotalText>
                </RowTotal>
              </RowInfo>
            </AsideArticle>
          </CartAside>
        </CartWrapper>
      </CartContainer>
    </div>
  )
}

Checkout.propTypes = {
  user: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object
}

Checkout.defaultProps = {
  activeLanguage: {}
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default withLocalize(connect(mapStateToProps)(Checkout))
