/* eslint-disable no-template-curly-in-string */

export default {
  OOPS_PAGE_NOT_FOUND: 'Oops... Page not found!',
  PAGE_NOT_FOUND_DESCRIPTION_1: 'We apologize for the inconvenience.',
  PAGE_NOT_FOUND_DESCRIPTION_2:
    'Apparently, the page that you are trying to find has been erased or never existed.',
  BACK_TO_INITIAL_PAGE: 'Back to initial page',
  PASSWORD_DOESNT_MATCH: "Password doesn't match",
  OUTFIT: 'OUTFIT',
  CREDITS: 'CREDITS',
  FITTING: 'FITTING',
  MY_ORDERS: 'MY ORDERS',
  ADDRESSES: 'ADDRESSES',
  SUPPORT: 'SUPPORT',
  ACCOUNT_SETTINGS: 'ACCOUNT SETTINGS',
  MY_BAG: 'My Bag',
  OUTFIT_TITLE: 'Uniform Items',
  OUTFIT_SUBTITLE:
    'Add to your basket all items you need to complete your uniform.',
  CREDIT_TITLE: 'Credits',
  CREDIT_SUBTITLE: 'Check your balance per item',
  ORDERS_TITLE: 'Orders',
  ORDERS_SUBTITLE: 'orders found',
  SUPPORT_TITLE: 'FAQ & Support',
  SUPPORT_SUBTITLE: '',
  FITTING_TITLE: 'Fitting',
  FITTING_SUBTITLE: "Check the uniform's measures",
  ADDRESSES_TITLE: 'Address book',
  ADDRESSES_SUBTITLE: 'Manage your addresses',
  PERSONAL_DATA: 'Personal data',
  LOGIN: 'Login',
  SIGNUP_NEWSLETTER: 'I want to sign up for the Skypro newsletter',
  NO_DATA_TO_DISPLAY: 'No data to Display!',
  REVOKE_ACCESS:
    'Revoke access to my data given under consent of the SKYPRO Privacy Policy (this operation will log you out and prevent usage of the mySkypro Portal)',
  ORDER_ALL_OUTFIT: 'ORDER ALL OUTFIT',
  SAVE: 'SAVE',
  EDIT: 'EDIT',
  ADD_NEW_ADDRESS: 'ADD NEW ADDRESS',
  CLOSE: 'CLOSE',
  PRIMARY_ADDRESS: 'Primary address',
  LOGOUT: 'LOGOUT',
  EMAIL_USERNAME: 'Email / Username',
  USERNAME: 'Username',
  CURRENT_PASSWORD: 'Current Password',
  NEW_PASSWORD: 'New Password',
  CONFIRM_PASSWORD: 'Confirm New Password',
  ADDRESS_NAME: 'Address name',
  NAME_FOR_DELIVERY: 'Name for delivery',
  ADDRESS_LINE_1: 'Address line 1',
  ADDRESS_LINE_2: 'Address line 2',
  ADDRESS_LINE_3: 'Address line 3',
  STREET_NUMBER: 'Street number',
  ZIP_CODE: 'ZIP Code',
  CITY: 'City',
  REGION: 'Region',
  COUNTRY: 'Country',
  PHONE_NUMBER: 'Phone number',
  VAT_NUMBER: 'VAT number',
  ADD_NEW_ADDRESS_LOWER: 'Add new address',
  USED_CREDITS: 'USED CREDITS',
  ITEMS_IN_OUTFIT: 'Items in your outfit',
  OTHER_ITEMS_FOR_YOU: 'Other items for you',
  OTHER_ITEMS_DESCRIPTION:
    'We found other items that may complement your uniform.',
  WELCOME_LOGIN: 'Welcome to',
  WELCOME_SUB: 'Increase efficiency whilst empowering your crew',
  WELCOME_SUB_DEFAULT:
    'A Unique blend of know-how and technology that creates the best crew care solutions in the world for your company',
  SIGN_IN: 'Sign In',
  RECOVER: 'Recover',
  EMAIL_LOGIN: 'E-mail',
  PASSWORD_LOGIN: 'Password',
  LOG_IN: 'Log in',
  REMEMBER_ME: 'Remember me',
  RECOVER_PASSWORD: 'Forgot your password?',
  ACCOUNT: 'account',
  ORDERS: 'orders',
  FITTING_SMALL: 'fitting',
  HOW_CHANGE_PASSWORD: 'How do I change my password?',
  HOW_CHANGE_PASSWORD_ANSWER:
    'Click your name on the top right corner or <a href="/accountsettings">here</a> to go to your account definitions, where you can change your password to login to your account.',
  HOW_CHECK_CREDITS: 'How do I check my credits?',
  HOW_CHECK_CREDITS_ANSWER:
    'You can check your credits at <a href="/credits">Credits</a>. You will find a list with all the items on your uniform and their remaining and total credits, as well as their renewal date, if applicable.',
  HOW_CHANGE_ADDRESSES: 'How do I change/add addresses and contacts?',
  HOW_CHANGE_ADDRESSES_ANSWER:
    'Go to <a href="/addresses">Addresses</a> to change/add addresses and contacts. Click "Edit" to change the data or "Add new address" to include an additional address. Remember that you should update your contacts before completing an order.',
  HOW_CHECK_UNIFORM: 'How do I check my uniform?',
  HOW_CHECK_UNIFORM_ANSWER:
    'You can check your uniform items at <a href="/outfit">Outfit</a>. Only the items that make up your uniform are displayed.',
  HOW_CHANGE_SIZE: 'How do I change the size of my uniform?',
  HOW_CHANGE_SIZE_ANSWER:
    'At <a href="/measures">Measures</a>, schedule a new fitting session by clicking on "Reschedule" and picking a date, time and place.',
  HOW_SCHEDULE_FITTING: 'How do I schedule a fitting?',
  HOW_SCHEDULE_FITTING_ANSWER:
    'Go to <a href="/measures">Measures</a>, click "Schedule" or "Reschedule" and pick a date, time and place.',
  HOW_CHECK_STATUS: 'How do I check the status of my order?',
  HOW_CHECK_STATUS_ANSWER:
    'Go to <a href="/myorders">My Orders</a>, search for your order number and click the "+" icon to check more details about your order.',
  RECEIVED_INCOMPLETE: 'I received an incomplete order.',
  RECEIVED_INCOMPLETE_ANSWER:
    'It is possible that your order was shipped in parcels. The order will be completed as soon as there is available stock. After the expedition you will receive an e-mail that will include the item(s) with the tracking number for each delivery.',
  RECEIVED_INCORRECT: 'I received incorrect item(s).',
  RECEIVED_INCORRECT_ANSWER:
    'You received the items that you selected and signed upon the moment the fitting was taken. If there is a wrong item caused by our logistic services, please contact cs@wearskypro.com. \n\n Note: your company does not accept returns of items of sizes that you selected.\n Note: the shipping of items with new sizes for exchange reasons has shipping costs that you will have to pay.',
  PAYMENT_METHODS: 'Payment methods.',
  PAYMENT_METHODS_ANSWER:
    "We accept the following types of payments:\n VISA\n ATM\n Paypal\n MasterCard\n American Express\n\n We assure that your payment can be done with safety in accordance with the international on-line payment security standards.\n Your credit card number is inserted in a secure page, hosted by our bank, and therefore external to our site. No credit card number is recorded or visible to our site during the payment process. We don't have access to your payment informations.\n This website uses the Secure Sockets Layer (SSL) technology to provide the most secure shopping experience possible. The SSL technology allows the encryption (encoding) of confidential information during your online transactions. All the forms on our site are protected with the same SSL technology, so that your personal information remains safe.",
  REFUND_PERIOD: 'Refund period.',
  REFUND_PERIOD_ANSWER:
    "Refunds are done using the same payment method used to process the order, in a period of 5 to 10 work days, after the reception of the returned item(s).\n\n If you pay with a gift card or payment card (virtual or physical), the refund will be credited on the same card. If by the time of the refund you don't have the card used on the payment with you anymore, we suggest that you contact our Customer Support Department at cs@wearskypro.com.",
  INCIDENT_UNIFORM:
    'While working, I had an incident with my uniform. Can I exchange it?',
  INCIDENT_UNIFORM_ANSWER:
    'Go to <a href="/orders">Orders</a>, "uniform incidents" and explain the situation. In case your complaint is accepted, a notification will be sent, stating that a new item is available to be sent to replace the damaged one.\n In case your complaint is not accepted, a notification will be sent stating the reason.',
  GENERAL: 'General',
  PAYMENTS: 'Payments',
  RETURNS_EXCHANGES: 'Returns & Exchanges',
  HELPFUL: 'Helpful',
  NOT_HELPFUL: 'Not Helpful',
  EXTRA_HELP: 'Need extra help?',
  DETAILS_HEADER: 'Details',
  COMPOSITION: 'Composition',
  COMPOSITION_ANSWER: '50% cotton, 47% polyamide, 3% elastane',
  WEIGHT: 'Weight',
  WEIGHT_ANSWER: '335grs',
  WIDTH: 'Width',
  WIDTH_ANSWER: '160CM',
  TEXTILE: 'Textile',
  TWILL: 'Twill',
  TECH_SUBTITLE: 'Full protection and safety for your intense work life.',
  ABSOLUTE_COMFORT: 'Absolute Comfort',
  ABSOLUTE_COMFORT_TEXT:
    "Skypro insoles use a special microcellular structure material with amazing features. They don't lose cushioning, are breathable, soft and resilient, absorb ground impact hence protecting the spine and joints and provide higher flexibility for absolute comfort.",
  EASY_CLEAN: 'Easy Cleaning',
  EASY_CLEAN_TEXT:
    'By improving repellent characteristics of the surface of our fabrics, using carefully selected, top of the line technology, allows for easy removal of stains. With this finishing the drying properties of the fabric are improved and we guarantee a much more durable garment.',
  ECOLOGIC: 'Ecologic',
  ECOLOGIC_TEXT:
    'Guarantee a minimal waste, packaging and environmental footprint in line with UN recommendations, prioritizing recycling and reused packaging, and promoting the use of sustainable, renewable and ecological raw materials.',
  RELATED_TITLE: 'Other items for you',
  COLORS_TITLE: 'Available colors',
  IMAGE: 'Image',
  TECHNOLOGY: 'Technology',
  RELATED: 'Related',
  CHECKOUT: 'Checkout',
  CONFIRM_DETAILS: 'Confirm your details below',
  CONTACT: 'Contact',
  SEND_TO: 'Ship to',
  PAYMENT_METHOD: 'Payment method',
  SECURE_ENCRYPTED: 'All transactions are secure and encrypted',
  CHANGE: 'Change',
  CREDIT_CARD: 'Credit card',
  CREDIT_CARD_TEXT:
    "When you click in Complete Order you'll be redirected to the payment page",
  ATM: 'Multibanco/ATM reference',
  ATM_TEXT: 'You will receive an e-mail with the payment details',
  BILLING_DATA: 'Billing info',
  INVOICE_TO: 'Invoice to',
  COMPLETE_ORDER: 'Complete order',
  REFUND_POLICY: 'Refund policy',
  PRIVACY_POLICY: 'Privacy policy',
  TERMS_SERVICE: 'Terms of service',
  VOUCHER_CODE: 'Voucher code',
  APPLY: 'Apply',
  SUBTOTAL: 'Subtotal',
  TRANSPORTATION: 'Shipping',
  TOTAL: 'Total',
  NO_ITEMS: 'You have no items in your shopping bag.',
  SCROLL_DOWN: 'Scroll down',
  COLOR: 'COLOR',
  STATUS: 'Status',
  VALUE_TAG: 'Closed',
  STATUS_TAG: 'Renews on:',
  ORDER_BTN: 'Order complete uniform',
  FUNCTION: 'Function:',
  OUTFIT_ITEMS: 'Items in Outfit:',
  NEXT_FITTING: 'Next Fitting:',
  RESCHEDULE: 'Reschedule',
  SCHEDULE: 'Schedule',
  ABOUT: 'About Skypro',
  PROHEALTH: 'ProHealth Technology',
  CUSTOMER: 'Customer Support',
  FOLLOW: 'Follow us',
  NEWSLETTER: 'Newsletter',
  BLOG: 'Blog',
  COPYRIGHT: '©${date} SKYPRO. All rights reserved.',
  ORDER: 'Order',
  DATE: 'Date',
  DELIVERY: 'Delivery',
  NO_ORDERS: 'No orders found',
  DELIVERY_ADDRESS: 'Delivery Address',
  PROBLEM_CONTACT: 'Problem? Contact us',
  ORDER_SUMMARY: 'Order Summary',
  ITEMS: 'Items',
  POSTAGE_PACKING: 'Postage & Packing',
  ORDER_TOTAL: 'Order total',
  DATE_SUBMISSION: 'Date of submission',
  ESTIMATED_DELIVERY: 'Estimated Delivery',
  PAYMENT_STATE: 'Payment state',
  GET_REFERENCE: 'Proceed to payment',
  QUANTITY: 'Quantity',
  ADD_ADDRESS: 'Add new address',
  EDIT_ADDRESS: 'Edit address',
  SUBMIT: 'Submit',
  ADDRESS_BOOK: 'Address book',
  MANAGE_ADDRESS: 'Manage your addresses',
  CITY_REQUIRED: 'City is a required field',
  SCHEDULE_FITTING_TITLE: 'Schedule fitting',
  SCHEDULE_FITTING_SUBTITLE: 'Choose where you want to get measured',
  HELLO: 'Hello',
  CREDITS_LEFT: 'CREDITS LEFT',
  OF: 'OF',
  RENEWS_ON: 'RENEWS ON',
  DURATION: 'DURATION',
  BACK_TO_CALENDAR: 'Back to calendar',
  COMPLETE_APPOINTMENT: 'Complete Appointment',
  EMPLOYEE: 'Employee',
  ITEMS_OUTFIT: 'Items in Outfit',
  LAST_FITTING: 'Last Fitting',
  PREVIOUS: 'Previous',
  NEXT: 'Next',
  CHOOSE_SIZE: 'Choose the size or scan automatically',
  DECLARE_MEASURES: 'I hereby declare that the measurements are correct',
  INSTRUCTIONS:
    'Follow the instructions on the <a href="../../files/fitting_guide.mp4" target="_blank" rel="noopener noreferrer">Tutorial Video</a> and then consult the <a href="../../files/fitting_sizes_table.pdf" target="_blank" rel="noopener noreferrer">Reference Table</a>',
  UNIQUE_SIZE: 'UNIQUE SIZE',
  SIZE: 'SIZE: ',
  REF_FITTING: 'Ref Fitting',
  TELEPHONE: 'Telephone',
  HOUR: 'Hour',
  SELECT: 'Select',
  CHECKOUT_NOW: 'Checkout now',
  CLEAR_BAG: 'Clear bag',
  YOU_WILL_LIKE: 'You will like',
  MONTHS: 'months',
  ADD_BAG: 'Add to bag',
  REMOVE_FROM_BAG: 'Remove from bag',
  SELECT_QUESTION_TYPE:
    'If you prefer, select the question type and consult our FAQ:',
  FITTING_LOWERCASE: 'fitting',
  NOT_FITTED: 'NOT FITTED',
  FITTED: 'FITTED',
  NA: 'NA',
  SAVE_CHANGES: 'SAVE CHANGES',
  BIRTH_DATE_OPTIONAL: 'Birth date (optional)',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  SORT_BY: 'Sort By',
  CART_CREDIT: 'credit',
  CART_CREDITS: 'credits',
  CART_ADD_ADDRESS: 'Add address',
  CART_SHIPMENT_TEXT_1: 'Maximum of',
  CART_SHIPMENT_TEXT_2: 'shipments per year.',
  CART_SHIPMENT_TEXT_3: 'shipments still available.',
  PAYMENT_RECEIVED: 'Received',
  PAYMENT_PENDING: 'Pending',
  DOCUMENTS: 'Documents',
  NOTES: 'Notes',
  TRACKING: 'Tracking',
  PARTIALLY: 'Partially',
  COOKIES_WARNING:
    "We use cookies to enhance your user experience on our site. If you continue browsing, we'll assume that you consent to our",
  COOKIES_WARNING_PRIVACY: 'privacy policies',
  SINGLE_SIZE: 'SINGLE SIZE',
  CUSTOMER_SUPPORT_EMAIL: 'Customer support e-mail:',
  CUSTOMER_SUPPORT_PHONE:
    'For direct customer support, please use the phone number that is in your geographic area.',
  CUSTOMER_SUPPORT_EUROPE: 'Portugal & European Countries',
  CUSTOMER_SUPPORT_USA: 'USA',
  CUSTOMER_SUPPORT_UNITED_KINGDOM: 'United Kingdom',
  CUSTOMER_SUPPORT_CANADA: 'Canada',
  CUSTOMER_SUPPORT_OBS_1: 'Call cost to national',
  CUSTOMER_SUPPORT_0BS_2: 'fixed network',
  NAME: 'Name',
  SUBJECT_PROBLEM_EMAIL: 'I have an issue with my Order',
  ORDER_FOUND: ' order found',
  ORDERS_FOUND: ' orders found',
  PRODUCT: 'Product',
  CHOOSE_YOUR_SIZE: 'CHOOSE YOUR SIZE',
  RECOVER_SUCCESS: "A new password was sent to the user's e-mail",
  SIGN_UP: 'New to the portal?',
  SIGN_UP_LINK: 'SIGN UP',
  RETURN_TO_LOGIN_PAGE: 'Return to Login',
  ONBOARDING: 'Onboarding',
  ONBOARDING_CONTENT_TITLE: 'Welcome to the uniform portal',
  ONBOARDING_CONTENT_SUBTITLE:
    'Please fill out the required information below:',
  ACCEPT: 'Accept',
  GENDER: 'Gender',
  DISTRICT: 'District',
  ADDRESS: 'Address',
  DAY: 'Day',
  MONTH: 'Month',
  YEAR: 'Year',
  JANUARY: 'January',
  FEBRUARY: 'February',
  MARCH: 'March',
  APRIL: 'April',
  MAY: 'May',
  JUNE: 'June',
  JULY: 'July',
  AUGUST: 'August',
  SEPTEMBER: 'September',
  OCTOBER: 'October',
  NOVEMBER: 'November',
  DECEMBER: 'December',
  ERROR: 'Error',
  ERROR_CURRENT_PASSWORD_REQUIRED: 'Current password is a mandatory field',
  ERROR_PASSWORD_CONFIRMATION_DOES_NOT_MATCH:
    'Password confirmation does not match the password',
  ERROR_PASSWORD_EQUAL_PREVIOUS:
    'The current password is equal to the previous password. Please use a different one',
  ERROR_DATA_SAVED_FAILED: 'Unable to change data, please try again',
  SUCCESS: 'Success',
  SUCCESS_DATA_SAVED_SUCCESSFULLY: 'Data updated successfully',
  AFGHANISTAN: 'Afghanistan',
  ALAND_ISLANDS: 'Aland Islands',
  ALBANIA: 'Albania',
  ALGERIA: 'Algeria',
  AMERICAN_SAMOA: 'American Samoa',
  ANDORRA: 'Andorra',
  ANGOLA: 'Angola',
  ANGUILLA: 'Anguilla',
  ANTARCTICA: 'Antarctica',
  ANTIGUA_AND_BARBUDA: 'Antigua and Barbuda',
  ARGENTINA: 'Argentina',
  ARMENIA: 'Armenia',
  ARUBA: 'Aruba',
  AUSTRALIA: 'Australia',
  AUSTRIA: 'Austria',
  AZERBAIJAN: 'Azerbaijan',
  BAHAMAS: 'Bahamas',
  BAHRAIN: 'Bahrain',
  BANGLADESH: 'Bangladesh',
  BARBADOS: 'Barbados',
  BELARUS: 'Belarus',
  BELGIUM: 'Belgium',
  BELIZE: 'Belize',
  BENIN: 'Benin',
  BERMUDA: 'Bermuda',
  BHUTAN: 'Bhutan',
  BOLIVIA: 'Bolivia',
  BOSNIA_AND_HERZEGOVINA: 'Bosnia and Herzegovina',
  BOTSWANA: 'Botswana',
  BOUVET_ISLAND: 'Bouvet Island',
  BRAZIL: 'Brazil',
  BRITISH_INDIAN_OCEAN_TERRITORY: 'British Indian Ocean Territory',
  BRITISH_VIRGIN_ISLANDS: 'British Virgin Islands',
  BRUNEI_DARUSSALAM: 'Brunei Darussalam',
  BULGARIA: 'Bulgaria',
  BURKINA_FASO: 'Burkina Faso',
  BURUNDI: 'Burundi',
  CAMBODIA: 'Cambodia',
  CAMEROON: 'Cameroon',
  CANADA: 'Canada',
  CANARY_ISLANDS: 'Canary Islands',
  CAPE_VERDE: 'Cape Verde',
  CAYMAN_ISLANDS: 'Cayman Islands',
  CENTRAL_AFRICAN_REPUBLIC: 'Central African Republic',
  CHAD: 'Chad',
  CHANNEL_ISLANDS: 'Channel Islands',
  CHILE: 'Chile',
  CHINA: 'China',
  CHRISTMAS_ISLAND: 'Christmas Island',
  COCOS_KEELING_ISLANDS: 'Cocos (Keeling) Islands',
  COLOMBIA: 'Colombia',
  COMOROS: 'Comoros',
  CONGO_KINSHASA: 'Congo (Kinshasa)',
  CONGO_BRAZZAVILLE: 'Congo (Brazzaville)',
  COOK_ISLANDS: 'Cook Islands',
  COSTA_RICA: 'Costa Rica',
  CROATIA: 'Croatia',
  CUBA: 'Cuba',
  CYPRUS: 'Cyprus',
  CZECHIA: 'Czechia',
  DENMARK: 'Denmark',
  DJIBOUTI: 'Djibouti',
  DOMINICA: 'Dominica',
  DOMINICAN_REPUBLIC: 'Dominican Republic',
  ECUADOR: 'Ecuador',
  EGYPT: 'Egypt',
  EL_SALVADOR: 'El Salvador',
  EQUATORIAL_GUINEA: 'Equatorial Guinea',
  ERITREA: 'Eritrea',
  ESTONIA: 'Estonia',
  ETHIOPIA: 'Ethiopia',
  FALKLAND_ISLANDS_MALVINAS: 'Falkland Islands (Malvinas)',
  FAROE_ISLANDS: 'Faroe Islands',
  FIJI: 'Fiji',
  FINLAND: 'Finland',
  FRANCE: 'France',
  FRENCH_GUIANA: 'French Guiana',
  FRENCH_POLYNESIA: 'French Polynesia',
  FRENCH_SOUTHERN_TERRITORIES: 'French Southern Territories',
  GABON: 'Gabon',
  GAMBIA: 'Gambia',
  GEORGIA: 'Georgia',
  GERMANY: 'Germany',
  GHANA: 'Ghana',
  GIBRALTAR: 'Gibraltar',
  GREECE: 'Greece',
  GREENLAND: 'Greenland',
  GRENADA: 'Grenada',
  GUADELOUPE: 'Guadeloupe',
  GUAM: 'Guam',
  GUATEMALA: 'Guatemala',
  GUERNSEY: 'Guernsey',
  GUINEA: 'Guinea',
  GUINEA_BISSAU: 'Guinea-Bissau',
  GUYANA: 'Guyana',
  HAITI: 'Haiti',
  HEARD_AND_MCDONALD_ISLANDS: 'Heard and Mcdonald Islands',
  HONDURAS: 'Honduras',
  HONG_KONG: 'Hong Kong',
  HUNGARY: 'Hungary',
  ICELAND: 'Iceland',
  INDIA: 'India',
  INDONESIA: 'Indonesia',
  IRAN_ISLAMIC_REPUBLIC_OF: 'Iran, Islamic Republic of',
  IRAQ: 'Iraq',
  IRELAND: 'Ireland',
  ISLE_OF_MAN: 'Isle of Man',
  ISRAEL: 'Israel',
  ITALY: 'Italy',
  IVORY_COAST: 'Ivory Coast',
  JAMAICA: 'Jamaica',
  JAPAN: 'Japan',
  JERSEY: 'Jersey',
  JORDAN: 'Jordan',
  KAZAKHSTAN: 'Kazakhstan',
  KENYA: 'Kenya',
  KIRIBATI: 'Kiribati',
  KOREA_NORTH: 'Korea (North)',
  KOREA_SOUTH: 'Korea (South)',
  KOSOVO: 'Kosovo',
  KUWAIT: 'Kuwait',
  KYRGYZSTAN: 'Kyrgyzstan',
  LAO_PDR: 'Lao PDR',
  LATVIA: 'Latvia',
  LEBANON: 'Lebanon',
  LESOTHO: 'Lesotho',
  LIBERIA: 'Liberia',
  LIBYA: 'Libya',
  LIECHTENSTEIN: 'Liechtenstein',
  LITHUANIA: 'Lithuania',
  LUXEMBOURG: 'Luxembourg',
  MACAO: 'Macao',
  MADAGASCAR: 'Madagascar',
  MALAWI: 'Malawi',
  MALAYSIA: 'Malaysia',
  MALDIVES: 'Maldives',
  MALI: 'Mali',
  MALTA: 'Malta',
  MARSHALL_ISLANDS: 'Marshall Islands',
  MARTINIQUE: 'Martinique',
  MAURITANIA: 'Mauritania',
  MAURITIUS: 'Mauritius',
  MAYOTTE: 'Mayotte',
  MEXICO: 'Mexico',
  MICRONESIA_FEDERATED_STATES_OF: 'Micronesia, Federated States of',
  MOLDOVA: 'Moldova',
  MONACO: 'Monaco',
  MONGOLIA: 'Mongolia',
  MONTENEGRO: 'Montenegro',
  MONTSERRAT: 'Montserrat',
  MOROCCO: 'Morocco',
  MOZAMBIQUE: 'Mozambique',
  MYANMAR: 'Myanmar',
  NAMIBIA: 'Namibia',
  NAURU: 'Nauru',
  NEPAL: 'Nepal',
  NETHERLANDS: 'Netherlands',
  NETHERLANDS_ANTILLES: 'Netherlands Antilles',
  NEW_CALEDONIA: 'New Caledonia',
  NEW_ZEALAND: 'New Zealand',
  NICARAGUA: 'Nicaragua',
  NIGER: 'Niger',
  NIGERIA: 'Nigeria',
  NIUE: 'Niue',
  NORFOLK_ISLAND: 'Norfolk Island',
  NORTH_MACEDONIA: 'North Macedonia',
  NORTHERN_MARIANA_ISLANDS: 'Northern Mariana Islands',
  NORWAY: 'Norway',
  OMAN: 'Oman',
  PAKISTAN: 'Pakistan',
  PALAU: 'Palau',
  PALESTINIAN_TERRITORY: 'Palestinian Territory',
  PANAMA: 'Panama',
  PAPUA_NEW_GUINEA: 'Papua New Guinea',
  PARAGUAY: 'Paraguay',
  PERU: 'Peru',
  PHILIPPINES: 'Philippines',
  PITCAIRN: 'Pitcairn',
  POLAND: 'Poland',
  PORTUGAL: 'Portugal',
  PUERTO_RICO: 'Puerto Rico',
  QATAR: 'Qatar',
  REUNION_ISLAND: 'Reunion Island',
  ROMANIA: 'Romania',
  RUSSIAN_FEDERATION: 'Russian Federation',
  RWANDA: 'Rwanda',
  SAINT_BARTHOLOMEW: 'Saint Bartholomew',
  SAINT_HELENA: 'Saint Helena',
  SAINT_KITTS_AND_NEVIS: 'Saint Kitts and Nevis',
  SAINT_LUCIA: 'Saint Lucia',
  SAINT_PIERRE_AND_MIQUELON: 'Saint Pierre and Miquelon',
  SAINT_VINCENT_AND_GRENADINES: 'Saint Vincent and Grenadines',
  SAINT_MARTIN_FRENCH_PART: 'Saint-Martin (French part)',
  SAMOA: 'Samoa',
  SAN_MARINO: 'San Marino',
  SAO_TOME_AND_PRINCIPE: 'Sao Tome and Principe',
  SAUDI_ARABIA: 'Saudi Arabia',
  SENEGAL: 'Senegal',
  SERBIA: 'Serbia',
  SEYCHELLES: 'Seychelles',
  SIERRA_LEONE: 'Sierra Leone',
  SINGAPORE: 'Singapore',
  SLOVAKIA: 'Slovakia',
  SLOVENIA: 'Slovenia',
  SOLOMON_ISLANDS: 'Solomon Islands',
  SOMALIA: 'Somalia',
  SOUTH_AFRICA: 'South Africa',
  SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS:
    'South Georgia and the South Sandwich Islands',
  SOUTH_SUDAN: 'South Sudan',
  SPAIN: 'Spain',
  SRI_LANKA: 'Sri Lanka',
  SUDAN: 'Sudan',
  SURINAME: 'Suriname',
  SVALBARD_AND_JAN_MAYEN_ISLANDS: 'Svalbard and Jan Mayen Islands',
  SWAZILAND: 'Swaziland',
  SWEDEN: 'Sweden',
  SWITZERLAND: 'Switzerland',
  SYRIAN_ARAB_REPUBLIC_SYRIA: 'Syrian Arab Republic (Syria)',
  TAIWAN_REPUBLIC_OF_CHINA: 'Taiwan, Republic of China',
  TAJIKISTAN: 'Tajikistan',
  TANZANIA_UNITED_REPUBLIC_OF: 'Tanzania, United Republic of',
  THAILAND: 'Thailand',
  TIMOR_LESTE: 'Timor-Leste',
  TOGO: 'Togo',
  TOKELAU: 'Tokelau',
  TONGA: 'Tonga',
  TRINIDAD_AND_TOBAGO: 'Trinidad and Tobago',
  TUNISIA: 'Tunisia',
  TURKEY: 'Turkey',
  TURKMENISTAN: 'Turkmenistan',
  TURKS_AND_CAICOS_ISLANDS: 'Turks and Caicos Islands',
  TUVALU: 'Tuvalu',
  UGANDA: 'Uganda',
  UKRAINE: 'Ukraine',
  UNITED_ARAB_EMIRATES: 'United Arab Emirates',
  UNITED_KINGDOM: 'United Kingdom',
  UNITED_STATES_OF_AMERICA: 'United States of America',
  URUGUAY: 'Uruguay',
  US_MINOR_OUTLYING_ISLANDS: 'US Minor Outlying Islands',
  UZBEKISTAN: 'Uzbekistan',
  VANUATU: 'Vanuatu',
  VATICAN_CITY: 'Vatican City',
  VENEZUELA_BOLIVARIAN_REPUBLIC: 'Venezuela (Bolivarian Republic)',
  VIETNAM: 'Vietnam',
  VIRGIN_ISLANDS_US: 'Virgin Islands, US',
  WALLIS_AND_FUTUNA_ISLANDS: 'Wallis and Futuna Islands',
  WESTERN_SAHARA: 'Western Sahara',
  YEMEN: 'Yemen',
  ZAMBIA: 'Zambia',
  ZIMBABWE: 'Zimbabwe',
  SUCCESS_ADDRESS_SAVED_SUCCESSFULLY: 'Address saved successfully',
  ORDER_REQUEST_WAS_COMPLETED: 'Your order was completed successfully',
  ORDER_REQUEST_WAS_RECEIVED: 'Your order request was received',
  ORDER_NUMBER: 'Order Number',
  ORDER_COMPLETED_DETAILS_1:
    'An email with the details about your order has been sent to the email address that you provided.',
  ORDER_COMPLETED_DETAILS_2: 'Please keep it for your records.',
  ORDER_COMPLETED_DETAILS_3:
    'You can visit the My Orders page at any time to check the status of your order.',
  ORDER_RECEIVED_DETAILS_1:
    'The order details have been sent to the provided email address.',
  ORDER_RECEIVED_DETAILS_2:
    'Now, kindly await approval from your company.',
  ORDER_RECEIVED_DETAILS_3:
    'You can check the progress at any moment by visiting the "My Orders" section.',
  CONTINUE_SHOPPING: 'Continue shopping',
  BUTTON_MY_ORDERS: 'My orders',
  THANK_YOU: 'Thank you',
  FITTING_SCHEDULE_NON_EXISTANT:
    "Unfortunately there aren't any available slots to schedule your fitting session. Please contact your manager.",
  PROCEED_TO_MEASURES: 'Proceed to Measures',
  LAYOUT_VIEW: 'Layout View',
  IMAGE_VIEW: 'Image View',
  CHECK_TERMS_SERVICE:
    'You must declare that the measurements are correct',
  REJECT: 'Reject',
  PRIVACY_POLICY_CHECK:
    'I declare that I have read and acknowledged the SKYPRO Privacy Policy',
  PRIVACY_POLICY_CHECK_UNVALID:
    "You must accept SKYPRO's Privacy Policy to be able to use this portal",
  FITTING_PENDING: 'Fitting pending',
  FITTING_PENDING_MESSAGE:
    "You haven't completed your fitting for this item. If you're in need of an item please ${managerLink} or if you would like to schedule a fitting, please click ${scheduleLink}.",
  CONTACT_AIRLINE_MANAGER: 'contact your airline manager',
  HERE: 'here',
  NO_MORE_STOCK_AVAILABLE: 'No more stock available',
  ORDER_LIMIT_REACHED: 'You have reached the order limit for this item'
}
