import {CirclePicker as Circle} from 'react-color'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

import {
  PrimaryColor,
  PrimaryTextColor,
  TertiaryColor
} from '../../styles/_colors'
import {
  AddressSubTitle,
  AddressTextTitle,
  Assistant300,
  Assistant500,
  Assistant600,
  Assistant800,
  BodyText,
  PanelTopSubTitle,
  PrimaryTitle
} from '../../styles/_texts'

export const Container = styled.section`
  padding-bottom: 100px;
  display: block;
  background-color: ${TertiaryColor};
`

export const Heading = styled.section`
  padding-bottom: 80px;
  padding-top: 80px;
  display: block;
`

export const RelatedProductsWrapper = styled.div`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 50px;
`

export const TitleWrapper = styled(RelatedProductsWrapper)`
  max-width: 100%;
`

export const Title = styled.p`
  font-family: 'Campton';
  font-size: ${PrimaryTitle};
  text-align: center;
  letter-spacing: 0;
  margin-block-end: 0;
`

export const RelatedProductsList = styled.ul`
  font-size: 0;
  text-align: center;
  list-style: none;
`

export const ItemGenre = styled.div`
  min-height: 17px;
  text-transform: uppercase;
  text-align: left;
  font-family: ${Assistant300};
  line-height: 1.14;
  font-size: ${AddressSubTitle};
`

export const ItemName = styled.p`
  margin-top: 0px;
  height: 50px;
  display: block;
  text-align: left;
  font-size: ${AddressTextTitle};
  line-height: 1.6;
  font-family: ${Assistant800};
  letter-spacing: 1px;
  color: ${PrimaryTextColor};
  text-transform: uppercase;
`

export const ItemImageWrapper = styled.figure`
  position: relative;
  width: 100%;
  background-color: ${TertiaryColor};
  overflow: hidden;
  border: 1px solid #dedede;
  margin: 20px 0;
  display: block;
  max-width: 320px;
  background: #f5f5f5;
`

export const ItemImageLink = styled(Link)`
  text-decoration: none;
  outline: none;
`

export const ItemImage = styled.img`
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
`

export const ListItem = styled.li`
  display: inline-block;
  margin: 0 10px 20px;
  text-align: left;
  position: relative;
  vertical-align: top;
  width: calc(25% - 20px);

  &:hover {
    ${ItemImage} {
      transform: scale(1.2);
    }
  }
`

export const ProductCreditsContainer = styled.div`
  margin-top: 12px;
`

export const Separator = styled.div`
  border-bottom: 1px solid #dedede;
`

export const ColorsTitle = styled.p`
  margin-top: 0px;
  height: 50px;
  display: block;
  text-align: left;
  color: ${PrimaryTextColor};
  padding-left: 10px;
  font-family: ${Assistant500};
  text-transform: uppercase;
  font-size: ${PanelTopSubTitle};
  line-height: 36px;
`

export const Price = styled.span`
  line-height: 2.14;
  display: inline;
  text-transform: uppercase;
  text-align: left;
  color: ${PrimaryColor};
  font-family: ${Assistant800};
  font-size: ${BodyText};
  padding-left: 10px;
`

export const OriginalPrice = styled(Price)`
  text-decoration: line-through;
  display: inline;
  margin: 8px;
`

export const ColorSelector = styled(Circle)``

export const AvailableColors = styled.div``

export const ColorsSpan = styled.div`
  margin-bottom: 5px;
`

export const ColorsContainer = styled.ul`
  margin-right: 0px;
  width: 100%;
  position: relative;
  display: inline-block;
  padding-bottom: 50px;
  overflow: hidden;
  padding-left: 10px;
  text-align: left;
  margin-top: 14px;
  list-style: none;
  margin-bottom: 0px;
`

export const ColorWrapper = styled.li`
  padding: 0px;
  max-width: calc(36% - 20px);
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 100%;
`

export const ColorBorder = styled.div`
  border: 1px solid ${PrimaryTextColor};
  width: 18px;
  height: 18px;
  border-radius: 25px;
  padding: 2px;
  margin: 0px;
  text-align: center;
`

export const ColorDisplay = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 20px;
  margin: 0px;
  padding: 0px;
  text-align: center;
`

export const ColorsDisplayTitle = styled.p`
  margin: 0px;
  padding-top: 9px;
  padding-left: 10px;
  display: block;
  letter-spacing: normal;
  text-align: left;
  font-family: ${Assistant600};
  font-size: ${PanelTopSubTitle};
  text-transform: uppercase;
`
