import React from 'react'

import {PropTypes} from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import Currency from '../../../infra/utils/Currency'
import getTranslationNameFromArray from '../../utils/getTranslationNameFromArray'
import GetImage from '../Image'
import {
  AvailableColors,
  ColorBorder,
  ColorDisplay,
  ColorsContainer,
  ColorsDisplayTitle,
  ColorsSpan,
  ColorWrapper,
  ItemGenre,
  ItemImage,
  ItemImageLink,
  ItemImageWrapper,
  ItemName,
  OriginalPrice,
  Price,
  ProductCreditsContainer,
  Separator
} from './RelatedStyles'

const RelatedProductCard = ({product, user, activeLanguage}) => (
  <>
    <ItemGenre
      style={{
        color: user.configuration?.layoutColors?.contentText
      }}
    >
      {product?.product?.mainCategory?.categoryTranslation &&
        getTranslationNameFromArray(
          product.product.mainCategory.categoryTranslation,
          activeLanguage.code
        )}
    </ItemGenre>
    <ItemName>
      {getTranslationNameFromArray(
        product.product.productTranslation,
        activeLanguage.code
      )}
    </ItemName>
    <ItemImageWrapper>
      <ItemImageLink to={`/products/${product?.productId}`}>
        <ItemImage
          src={GetImage(
            product?.product?.productImage.find((x) => x.cover)
          )}
          alt={
            product.product.productTranslation.find(
              (x) => x.languageId === activeLanguage.code
            )?.name
          }
        />
      </ItemImageLink>
    </ItemImageWrapper>
    <ProductCreditsContainer>
      <Price>
        {Currency.format(product?.product?.totalValue, user.currencyCode)}
      </Price>
      {product?.product?.originalPrice && (
        <OriginalPrice>
          {Currency.format(
            product?.product?.originalPrice,
            user.currencyCode
          )}
        </OriginalPrice>
      )}
    </ProductCreditsContainer>
    <Separator />
    <AvailableColors>
      <>
        <ColorsDisplayTitle>
          <Translate id='COLORS_TITLE' />
        </ColorsDisplayTitle>
        <ColorsSpan />
        <ColorsContainer>
          {product.colorList.map((color, index) => (
            <ColorWrapper key={index}>
              <ColorBorder>
                <ColorDisplay style={{backgroundColor: `${color}`}} />
              </ColorBorder>
            </ColorWrapper>
          ))}
        </ColorsContainer>
      </>
    </AvailableColors>
  </>
)

RelatedProductCard.propTypes = {
  product: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  activeLanguage: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default withLocalize(connect(mapStateToProps)(RelatedProductCard))
