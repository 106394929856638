/* eslint-disable no-template-curly-in-string */

export default {
  OOPS_PAGE_NOT_FOUND: 'Oops... Página não encontrada!',
  PAGE_NOT_FOUND_DESCRIPTION_1: 'Pedimos desculpa pela inconveniência.',
  PAGE_NOT_FOUND_DESCRIPTION_2:
    'Aparentemente, a página que está a tentar aceder foi eliminada ou nunca existiu.',
  BACK_TO_INITIAL_PAGE: 'Voltar para página inicial.',
  PASSWORD_DOESNT_MATCH: 'As senhas não coincidem',
  OUTFIT: 'UNIFORME',
  CREDITS: 'CRÉDITOS',
  FITTING: 'MEDIDAS',
  MY_ORDERS: 'ENCOMENDAS',
  ADDRESSES: 'MORADAS',
  SUPPORT: 'SUPORTE',
  ACCOUNT_SETTINGS: 'GESTÃO DE CONTA',
  MY_BAG: 'Saco de compras',
  OUTFIT_TITLE: 'Itens do Uniforme',
  OUTFIT_SUBTITLE:
    'Adicione à sua cesta todos os itens necessários para completar seu uniforme.',
  CREDIT_TITLE: 'Créditos',
  CREDIT_SUBTITLE: 'Verifique o balanço por item',
  ORDERS_TITLE: 'Encomendas',
  ORDERS_SUBTITLE: 'encomendas',
  SUPPORT_TITLE: 'FAQ e suporte',
  SUPPORT_SUBTITLE: '',
  FITTING_TITLE: 'Medidas',
  FITTING_SUBTITLE: 'Confirme as medidas do uniforme',
  ADDRESSES_TITLE: 'Livro de moradas',
  ADDRESSES_SUBTITLE: 'Edite as suas moradas',
  PERSONAL_DATA: 'Dados pessoais',
  LOGIN: 'Login',
  SIGNUP_NEWSLETTER: 'Quero registrar-me na Newsletter Skypro',
  NO_DATA_TO_DISPLAY: 'Sem dados para mostrar!',
  REVOKE_ACCESS:
    'Revogar acesso aos meus dados concedido sob a Política de Privacidade da SKYPRO (esta operação irá terminar a sua sessão e impedir a utilização do portal mySkypro)',
  ORDER_ALL_OUTFIT: 'ENCOMENDAR TODOS',
  SAVE: 'GRAVAR',
  EDIT: 'EDITAR',
  ADD_NEW_ADDRESS: 'CRIAR NOVA MORADA',
  CLOSE: 'FECHAR',
  PRIMARY_ADDRESS: 'Morada principal',
  LOGOUT: 'SAIR',
  EMAIL_USERNAME: 'E-mail / Nome de utilizador',
  CURRENT_PASSWORD: 'Senha Atual',
  NEW_PASSWORD: 'Nova Senha',
  CONFIRM_PASSWORD: 'Confirmar Nova Senha',
  ADDRESS_NAME: 'Nome da morada',
  NAME_FOR_DELIVERY: 'Nome para entrega',
  ADDRESS_LINE_1: 'Linha morada 1',
  ADDRESS_LINE_2: 'Linha morada 2',
  ADDRESS_LINE_3: 'Linha morada 3',
  STREET_NUMBER: 'Número rua',
  ZIP_CODE: 'Código postal',
  CITY: 'Cidade',
  REGION: 'Região',
  COUNTRY: 'País',
  PHONE_NUMBER: 'Número telemóvel',
  VAT_NUMBER: 'NIF',
  ADD_NEW_ADDRESS_LOWER: 'Criar nova morada',
  USED_CREDITS: 'CRÉDITOS USADOS',
  ITEMS_IN_OUTFIT: 'Itens no seu outfit',
  OTHER_ITEMS_FOR_YOU: 'Outros itens para si',
  OTHER_ITEMS_DESCRIPTION:
    'Encontramos outros itens que complementam o seu uniforme',
  WELCOME_LOGIN: 'Bem-vindo ao',
  WELCOME_SUB: 'Aumente a eficiência enquanto capacita sua equipe',
  WELCOME_SUB_DEFAULT:
    'Uma mistura única de know-how e tecnologia que cria as melhores soluções de cuidado de tripulação do mundo para sua empresa',
  SIGN_IN: 'Entrar',
  RECOVER: 'Recuperar',
  EMAIL_LOGIN: 'E-mail',
  PASSWORD_LOGIN: 'Senha',
  USERNAME: 'Nome de utilizador',
  LOG_IN: 'Conecte-se',
  REMEMBER_ME: 'Lembre de mim',
  RECOVER_PASSWORD: 'Esqueceu sua senha?',
  ACCOUNT: 'conta',
  ORDERS: 'encomendas',
  FITTING_SMALL: 'fitting',
  HOW_CHANGE_PASSWORD: 'Como altero minha senha?',
  HOW_CHANGE_PASSWORD_ANSWER:
    'Clique no seu nome no canto superior direito ou <a href="/accountsettings">aqui</a> para acessar as definições da sua conta, onde você pode alterar sua senha para fazer login na sua conta.',
  HOW_CHECK_CREDITS: 'Como verifico meus créditos?',
  HOW_CHECK_CREDITS_ANSWER:
    'Você pode verificar seus créditos em <a href="/credits">Créditos</a>. Você encontrará uma lista com todos os itens do seu uniforme e seus créditos restantes e totais, bem como a data de renovação, se for o caso.',
  HOW_CHANGE_ADDRESSES: 'Como altero/adiciono moradas e contatos?',
  HOW_CHANGE_ADDRESSES_ANSWER:
    'Vá para <a href="/addresses">Moradas</a> para alterar/adicionar moradas e contatos. Clique em “Editar” para alterar os dados ou “Adicionar nova morada” para incluir uma morada adicional. Lembre-se que você deve atualizar seus contatos antes de finalizar um pedido.',
  HOW_CHECK_UNIFORM: 'Como faço para verificar meu uniforme?',
  HOW_CHECK_UNIFORM_ANSWER:
    'Você pode conferir os itens do seu uniforme em <a href="/outfit">Outfit</a>. Somente os itens que compõem seu uniforme serão exibidos.',
  HOW_CHANGE_SIZE: 'Como altero o tamanho do meu uniforme?',
  HOW_CHANGE_SIZE_ANSWER:
    'Em <a href="/measures">Medidas</a>, agende uma nova sessão de adaptação clicando em "Reagendar" e escolhendo data, horário e local.',
  HOW_SCHEDULE_FITTING: 'Como faço para agendar uma prova?',
  HOW_SCHEDULE_FITTING_ANSWER:
    'Vá para <a href="/measures">Medidas</a>, clique em "Agendar" ou "Reagendar" e escolha uma data, hora e local.',
  HOW_CHECK_STATUS: 'Como verifico o status do meu pedido?',
  HOW_CHECK_STATUS_ANSWER:
    'Acesse <a href="/myorders">Meus pedidos</a>, pesquise o número do seu pedido e clique no ícone "+" para verificar mais detalhes sobre seu pedido.',
  RECEIVED_INCOMPLETE: 'Recebi um pedido incompleto.',
  RECEIVED_INCOMPLETE_ANSWER:
    'É possível que seu pedido tenha sido enviado em pacotes. A encomenda será concluída assim que houver stock disponível. Após a expedição você receberá um e-mail que incluirá o(s) item(s) com o número de rastreamento de cada entrega.',
  RECEIVED_INCORRECT: 'Recebi itens incorretos.',
  RECEIVED_INCORRECT_ANSWER:
    'Você recebeu os itens que selecionou e assinou no momento da prova. Se houver um item errado causado por nossos serviços de logística, entre em contato com cs@wearskypro.com. \n\n Nota: a sua empresa não aceita devoluções de artigos nos tamanhos que selecionou.\n Nota: o envio de artigos com novos tamanhos por motivos de troca tem custos de envio que terá de pagar.',
  PAYMENT_METHODS: 'Métodos de Pagamento.',
  PAYMENT_METHODS_ANSWER:
    'Aceitamos os seguintes tipos de pagamentos:\n VISA\n Multibanco\n Paypal\n MasterCard\n American Express\n\n Garantimos que o seu pagamento pode ser feito com segurança de acordo com os padrões internacionais de segurança de pagamento on-line. \n O número do seu cartão de crédito é inserido em uma página segura, hospedada pelo nosso banco e, portanto, externa ao nosso site. Nenhum número de cartão de crédito é registrado ou visível em nosso site durante o processo de pagamento. Não temos acesso às suas informações de pagamento.\n Este site usa a tecnologia Secure Sockets Layer (SSL) para fornecer a experiência de compra mais segura possível. A tecnologia SSL permite a criptografia (codificação) de informações confidenciais durante suas transações online. Todos os formulários do nosso site estão protegidos com a mesma tecnologia SSL, para que as suas informações pessoais permaneçam seguras.',
  REFUND_PERIOD: 'Período de reembolso.',
  REFUND_PERIOD_ANSWER:
    'Os reembolsos são efetuados através do mesmo meio de pagamento utilizado para processar a encomenda, num prazo de 5 a 10 dias úteis, após a receção do(s) artigo(s) devolvido(s).\n\n Se pagar com cartão oferta ou cartão de pagamento ( virtual ou físico), o reembolso será creditado no mesmo cartão. Se no momento do reembolso você não tiver mais o cartão utilizado no pagamento, sugerimos que entre em contato com nosso Departamento de Atendimento ao Cliente em cs@wearskypro.com.',
  INCIDENT_UNIFORM:
    'Enquanto trabalhava, tive um incidente com meu uniforme. Posso trocá-lo?',
  INCIDENT_UNIFORM_ANSWER:
    'Vá para <a href="/orders">Pedidos</a>, "incidentes uniformes" e explique a situação. Caso sua reclamação seja aceita, será enviada uma notificação informando que um novo item está disponível para ser enviado em substituição ao danificado.\n Caso sua reclamação não seja aceita, será enviada uma notificação informando o motivo.',
  GENERAL: 'Geral',
  PAYMENTS: 'Pagamentos',
  RETURNS_EXCHANGES: 'Devoluções e Trocas',
  HELPFUL: 'Útil',
  NOT_HELPFUL: 'Não ajuda',
  EXTRA_HELP: 'Precisa de ajuda extra?',
  DETAILS_HEADER: 'Detalhes',
  COMPOSITION: 'Composição',
  COMPOSITION_ANSWER: '50% algodão, 47% poliamida, 3% elastano',
  WEIGHT: 'Peso',
  WEIGHT_ANSWER: '335gr',
  WIDTH: 'Largura',
  WIDTH_ANSWER: '160CM',
  TEXTILE: 'Têxtil',
  TWILL: 'Sarja',
  TECH_SUBTITLE:
    'Proteção total e segurança para sua intensa vida profissional.',
  ABSOLUTE_COMFORT: 'Conforto Absoluto',
  ABSOLUTE_COMFORT_TEXT:
    'As palmilhas Skypro utilizam um material de estrutura microcelular especial com características surpreendentes. Não perdem o amortecimento, são respiráveis, macios e resilientes, absorvem o impacto do solo protegendo assim a coluna e as articulações e proporcionam maior flexibilidade para um conforto absoluto.',
  EASY_CLEAN: 'Limpeza fácil',
  EASY_CLEAN_TEXT:
    'Ao melhorar as características repelentes da superfície dos nossos tecidos, utilizando tecnologia topo de gama cuidadosamente selecionada, permite uma fácil remoção de manchas. Com este acabamento as propriedades de secagem do tecido são melhoradas e garantimos uma peça de roupa muito mais durável.',
  ECOLOGIC: 'Ecológico',
  ECOLOGIC_TEXT:
    'Garantir um mínimo de resíduos, embalagens e pegada ambiental em linha com as recomendações da ONU, priorizando a reciclagem e reutilização de embalagens e promovendo a utilização de matérias-primas sustentáveis, renováveis e ecológicas.',
  RELATED_TITLE: 'Outros itens para você',
  COLORS_TITLE: 'Cores disponiveis',
  IMAGE: 'Imagem',
  TECHNOLOGY: 'Tecnologia',
  RELATED: 'Relacionado',
  CHECKOUT: 'Checkout',
  CONFIRM_DETAILS: 'Confirme seus dados abaixo',
  CONTACT: 'Contacto',
  SEND_TO: 'Enviar para',
  PAYMENT_METHOD: 'Método de pagamento',
  SECURE_ENCRYPTED: 'Todas as transações são seguras e criptografadas',
  CHANGE: 'Alterar',
  CREDIT_CARD: 'Cartão de crédito',
  CREDIT_CARD_TEXT:
    'Ao clicar em Concluir Pedido você será redirecionado para a página de pagamento',
  ATM: 'Multibanco/ATM referência',
  ATM_TEXT: 'Você receberá um e-mail com os dados de pagamento',
  BILLING_DATA: 'Informação de pagamento',
  INVOICE_TO: 'Fatura para',
  COMPLETE_ORDER: 'Concluir encomenda',
  REFUND_POLICY: 'Política de devoluções',
  PRIVACY_POLICY: 'Política de privacidade',
  TERMS_SERVICE: 'Termos de serviço',
  VOUCHER_CODE: 'Código voucher',
  APPLY: 'Aplicar',
  SUBTOTAL: 'Subtotal',
  TRANSPORTATION: 'Transporte',
  TOTAL: 'Total',
  NO_ITEMS: 'Não tem itens no seu saco de compras.',
  SCROLL_DOWN: 'Rolar para baixo',
  COLOR: 'COR',
  STATUS: 'Estado',
  VALUE_TAG: 'Fechado',
  STATUS_TAG: 'Renova em:',
  ORDER_BTN: 'Encomendar uniforme completo',
  FUNCTION: 'Função:',
  OUTFIT_ITEMS: 'Items do uniforme:',
  NEXT_FITTING: 'Próximo fitting:',
  RESCHEDULE: 'Remarcar',
  SCHEDULE: 'marcar',
  ABOUT: 'Sobre a Skypro',
  PROHEALTH: 'Tecnologia ProHealth',
  CUSTOMER: 'Suporte ao Cliente',
  FOLLOW: 'Segue-nos',
  NEWSLETTER: 'Newsletter',
  BLOG: 'Blog',
  COPYRIGHT: '©${date} SKYPRO. Todos os direitos reservados.',
  ORDER: 'Encomenda',
  DATE: 'Data',
  DELIVERY: 'Entrega',
  NO_ORDERS: 'Nenhuma encomenda encontrada',
  DELIVERY_ADDRESS: 'Morada de entrega',
  PROBLEM_CONTACT: 'Problema? Contacte-nos',
  ORDER_SUMMARY: 'Resumo da encomenda',
  ITEMS: 'Itens',
  POSTAGE_PACKING: 'Envio e empacotamento',
  ORDER_TOTAL: 'Total da encomenda',
  DATE_SUBMISSION: 'Data',
  ESTIMATED_DELIVERY: 'Entrega estimada',
  PAYMENT_STATE: 'Estado pagamento',
  GET_REFERENCE: 'prossiga para o pagamento',
  QUANTITY: 'Quantidade',
  ADD_ADDRESS: 'Adicionar nova morada',
  EDIT_ADDRESS: 'Editar morada',
  SUBMIT: 'Enviar',
  ADDRESS_BOOK: 'Livro de moradas',
  MANAGE_ADDRESS: 'Gerencie suas moradas',
  CITY_REQUIRED: 'Cidade é um campo obrigatório',
  SCHEDULE_FITTING_TITLE: 'Ajuste de cronograma',
  SCHEDULE_FITTING_SUBTITLE: 'Escolha onde você deseja ser medido',
  HELLO: 'Olá',
  CREDITS_LEFT: 'CRÉDITOS RESTANTES',
  OF: 'DE',
  RENEWS_ON: 'RENOVA EM',
  DURATION: 'DURAÇÃO',
  BACK_TO_CALENDAR: 'Voltar ao calendário',
  COMPLETE_APPOINTMENT: 'Concluir compromisso',
  EMPLOYEE: 'Empregado',
  ITEMS_OUTFIT: 'Itens em Outfit',
  LAST_FITTING: 'Último Fitting',
  PREVIOUS: 'Anterior',
  NEXT: 'Próximo',
  CHOOSE_SIZE:
    'Escolha o tamanho ou leia o código de barras automaticamente',
  DECLARE_MEASURES: 'Declaro que as medidas indicadas estão corretas',
  INSTRUCTIONS:
    'Siga as instruções no <a href="../../files/fitting_guide.mp4" target="_blank" rel="noopener noreferrer">Vídeo Tutorial</a> e depois consulte a <a href="../../files/fitting_sizes_table.pdf" target="_blank" rel="noopener noreferrer">Tabela de Referência</a>',
  UNIQUE_SIZE: 'TAMANHO ÚNICO',
  SIZE: 'TAMANHO: ',
  REF_FITTING: 'Ref Fitting',
  TELEPHONE: 'Telemóvel',
  HOUR: 'Hora',
  SELECT: 'Selecione',
  CHECKOUT_NOW: 'Encomendar agora',
  CLEAR_BAG: 'Limpar saco',
  YOU_WILL_LIKE: 'Vai gostar de',
  MONTHS: 'meses',
  ADD_BAG: 'encomendar',
  SELECT_QUESTION_TYPE:
    'Se preferir, selecione o tipo de pergunta e consulte nosso FAQ:',
  FITTING_LOWERCASE: 'fitting',
  NOT_FITTED: 'NÃO DEFINIDO',
  FITTED: 'DEFINIDO',
  NA: 'NA',
  SAVE_CHANGES: 'GUARDAR',
  BIRTH_DATE_OPTIONAL: 'Data de nascimento (opcional)',
  FIRST_NAME: 'Nome',
  LAST_NAME: 'Sobrenome',
  SORT_BY: 'Ordenar Por',
  CART_CREDIT: 'crédito',
  CART_CREDITS: 'créditos',
  CART_ADD_ADDRESS: 'Adicionar morada',
  CART_SHIPMENT_TEXT_1: 'Máximo de',
  CART_SHIPMENT_TEXT_2: 'envios por ano.',
  CART_SHIPMENT_TEXT_3: 'envios disponíveis.',
  PAYMENT_RECEIVED: 'Recebido',
  PAYMENT_PENDING: 'Pendente',
  DOCUMENTS: 'Documentos',
  NOTES: 'Notas',
  TRACKING: 'Rastreio',
  PARTIALLY: 'Parcialmente',
  COOKIES_WARNING:
    'Utilizamos cookies para melhorar a sua experiência de usuário em nosso site. Se você continuar navegando, presumiremos que você concorda com nossas',
  COOKIES_WARNING_PRIVACY: 'políticas de privacidade',
  SINGLE_SIZE: 'TAMANHO ÚNICO',
  CUSTOMER_SUPPORT_EMAIL: 'E-mail de suporte ao cliente:',
  CUSTOMER_SUPPORT_PHONE:
    'Para suporte direto ao cliente, por favor, use o número de telefone que está em sua área geográfica.',
  CUSTOMER_SUPPORT_EUROPE: 'Portugal e países europeus',
  CUSTOMER_SUPPORT_USA: 'Estados Unidos',
  CUSTOMER_SUPPORT_UNITED_KINGDOM: 'Reino Unido',
  CUSTOMER_SUPPORT_CANADA: 'Canadá',
  CUSTOMER_SUPPORT_OBS_1: 'Custo da chamada para',
  CUSTOMER_SUPPORT_0BS_2: 'rede fixa nacional',
  NAME: 'Nome',
  SUBJECT_PROBLEM_EMAIL: 'Tenho um problema com meu pedido',
  ORDER_FOUND: ' encomenda encontrada',
  ORDERS_FOUND: ' encomendas encontradas',
  PRODUCT: 'Produto',
  CHOOSE_YOUR_SIZE: 'ESCOLHA SEU TAMANHO',
  RECOVER_SUCCESS: "A new password was sent to the user's e-mail",
  SIGN_UP: 'Novo no portal?',
  SIGN_UP_LINK: 'INSCREVER-SE',
  RETURN_TO_LOGIN_PAGE: 'Retornar para Login',
  ONBOARDING: 'Onboarding',
  ONBOARDING_CONTENT_TITLE: 'Bem-vindo ao portal de uniformes',
  ONBOARDING_CONTENT_SUBTITLE:
    'Por favor, preencha as informações solicitadas abaixo:',
  ACCEPT: 'Aceitar',
  GENDER: 'Gênero',
  DISTRICT: 'Distrito',
  ADDRESS: 'Endereço',
  DAY: 'Dia',
  MONTH: 'Mês',
  YEAR: 'Ano',
  JANUARY: 'Janeiro',
  FEBRUARY: 'Fevereiro',
  MARCH: 'Março',
  APRIL: 'Abril',
  MAY: 'Maio',
  JUNE: 'Junho',
  JULY: 'Julho',
  AUGUST: 'Agosto',
  SEPTEMBER: 'Setembro',
  OCTOBER: 'Outubro',
  NOVEMBER: 'Novembro',
  DECEMBER: 'Dezembro',
  ERROR: 'Erro',
  ERROR_CURRENT_PASSWORD_REQUIRED: 'A senha atual é um campo obrigatório',
  ERROR_PASSWORD_CONFIRMATION_DOES_NOT_MATCH:
    'A confirmação da senha não corresponde à senha',
  ERROR_PASSWORD_EQUAL_PREVIOUS:
    'A nova senha é igual à anterior. Por favor escolha uma diferente',
  ERROR_DATA_SAVED_FAILED:
    'Não foi possível alterar os dados, por favor tente novamente',
  SUCCESS: 'Sucesso',
  SUCCESS_DATA_SAVED_SUCCESSFULLY: 'Dados alterados com sucesso',
  AFGHANISTAN: 'Afeganistão',
  ALAND_ISLANDS: 'Ilhas Aland',
  ALBANIA: 'Albânia',
  ALGERIA: 'Argélia',
  AMERICAN_SAMOA: 'Samoa Americana',
  ANDORRA: 'Andorra',
  ANGOLA: 'Angola',
  ANGUILLA: 'Anguila',
  ANTARCTICA: 'Antártica',
  ANTIGUA_AND_BARBUDA: 'Antígua e Barbuda',
  ARGENTINA: 'Argentina',
  ARMENIA: 'Armênia',
  ARUBA: 'Aruba',
  AUSTRALIA: 'Austrália',
  AUSTRIA: 'Áustria',
  AZERBAIJAN: 'Azerbaijão',
  BAHAMAS: 'Bahamas',
  BAHRAIN: 'Bahrein',
  BANGLADESH: 'Bangladesh',
  BARBADOS: 'Barbados',
  BELARUS: 'Bielorrússia',
  BELGIUM: 'Bélgica',
  BELIZE: 'Belize',
  BENIN: 'Benim',
  BERMUDA: 'Bermudas',
  BHUTAN: 'Butão',
  BOLIVIA: 'Bolívia',
  BOSNIA_AND_HERZEGOVINA: 'Bósnia e Herzegovina',
  BOTSWANA: 'Botsuana',
  BOUVET_ISLAND: 'Ilha Bouvet',
  BRAZIL: 'Brasil',
  BRITISH_INDIAN_OCEAN_TERRITORY: 'Território Britânico do Oceano Índico',
  BRITISH_VIRGIN_ISLANDS: 'Ilhas Virgens Britânicas',
  BRUNEI_DARUSSALAM: 'Brunei Darussalam',
  BULGARIA: 'Bulgária',
  BURKINA_FASO: 'Burkina Faso',
  BURUNDI: 'Burundi',
  CAMBODIA: 'Camboja',
  CAMEROON: 'Camarões',
  CANADA: 'Canadá',
  CANARY_ISLANDS: 'Ilhas Canárias',
  CAPE_VERDE: 'Cabo Verde',
  CAYMAN_ISLANDS: 'Ilhas Cayman',
  CENTRAL_AFRICAN_REPUBLIC: 'República Centro-Africana',
  CHAD: 'Chade',
  CHANNEL_ISLANDS: 'Ilhas do Canal',
  CHILE: 'Chile',
  CHINA: 'China',
  CHRISTMAS_ISLAND: 'Ilha do Natal',
  COCOS_KEELING_ISLANDS: 'Ilhas Cocos (Keeling)',
  COLOMBIA: 'Colômbia',
  COMOROS: 'Comores',
  CONGO_KINSHASA: 'Congo (Kinshasa)',
  CONGO_BRAZZAVILLE: 'Congo (Brazavile)',
  COOK_ISLANDS: 'Ilhas Cook',
  COSTA_RICA: 'Costa Rica',
  CROATIA: 'Croácia',
  CUBA: 'Cuba',
  CYPRUS: 'Chipre',
  CZECHIA: 'Tcheca',
  DENMARK: 'Dinamarca',
  DJIBOUTI: 'Djibuti',
  DOMINICA: 'Domínica',
  DOMINICAN_REPUBLIC: 'República Dominicana',
  ECUADOR: 'Equador',
  EGYPT: 'Egito',
  EL_SALVADOR: 'El Salvador',
  EQUATORIAL_GUINEA: 'Guiné Equatorial',
  ERITREA: 'Eritreia',
  ESTONIA: 'Estônia',
  ETHIOPIA: 'Etiópia',
  FALKLAND_ISLANDS_MALVINAS: 'Ilhas Falkland (Malvinas)',
  FAROE_ISLANDS: 'Ilhas Faroé',
  FIJI: 'Fiji',
  FINLAND: 'Finlândia',
  FRANCE: 'França',
  FRENCH_GUIANA: 'Guiana Francesa',
  FRENCH_POLYNESIA: 'Polinésia Francesa',
  FRENCH_SOUTHERN_TERRITORIES: 'Territórios Franceses do Sul',
  GABON: 'Gabão',
  GAMBIA: 'Gâmbia',
  GEORGIA: 'Geórgia',
  GERMANY: 'Alemanha',
  GHANA: 'Gana',
  GIBRALTAR: 'Gibraltar',
  GREECE: 'Grécia',
  GREENLAND: 'Groenlândia',
  GRENADA: 'Granada',
  GUADELOUPE: 'Guadalupe',
  GUAM: 'Guam',
  GUATEMALA: 'Guatemala',
  GUERNSEY: 'Guernsey',
  GUINEA: 'Guiné',
  GUINEA_BISSAU: 'Guiné-Bissau',
  GUYANA: 'Guiana',
  HAITI: 'Haiti',
  HEARD_AND_MCDONALD_ISLANDS: 'Ilhas Heard e Mcdonald',
  HONDURAS: 'Honduras',
  HONG_KONG: 'Hong Kong',
  HUNGARY: 'Hungria',
  ICELAND: 'Islândia',
  INDIA: 'Índia',
  INDONESIA: 'Indonésia',
  IRAN_ISLAMIC_REPUBLIC_OF: 'Irã (República Islâmica do Irã)',
  IRAQ: 'Iraque',
  IRELAND: 'Irlanda',
  ISLE_OF_MAN: 'Ilha de Man',
  ISRAEL: 'Israel',
  ITALY: 'Itália',
  IVORY_COAST: 'Costa do Marfim',
  JAMAICA: 'Jamaica',
  JAPAN: 'Japão',
  JERSEY: 'Jersey',
  JORDAN: 'Jordânia',
  KAZAKHSTAN: 'Cazaquistão',
  KENYA: 'Quênia',
  KIRIBATI: 'Quiribáti',
  KOREA_NORTH: 'Coréia do Norte',
  KOREA_SOUTH: 'Coréia do Sul',
  KOSOVO: 'Kosovo',
  KUWAIT: 'Kuwait',
  KYRGYZSTAN: 'Quirguistão',
  LAO_PDR: 'República Popular Democrática do Laos',
  LATVIA: 'Letônia',
  LEBANON: 'Líbano',
  LESOTHO: 'Lesoto',
  LIBERIA: 'Libéria',
  LIBYA: 'Líbia',
  LIECHTENSTEIN: 'Liechtenstein',
  LITHUANIA: 'Lituânia',
  LUXEMBOURG: 'Luxemburgo',
  MACAO: 'Macau',
  MADAGASCAR: 'Madagáscar',
  MALAWI: 'Maláui',
  MALAYSIA: 'Malásia',
  MALDIVES: 'Maldivas',
  MALI: 'Mali',
  MALTA: 'Malta',
  MARSHALL_ISLANDS: 'Ilhas Marshall',
  MARTINIQUE: 'Martinica',
  MAURITANIA: 'Mauritânia',
  MAURITIUS: 'Maurício',
  MAYOTTE: 'Maiote',
  MEXICO: 'México',
  MICRONESIA_FEDERATED_STATES_OF: 'Micronésia, Estados Federados da',
  MOLDOVA: 'Moldávia',
  MONACO: 'Mônaco',
  MONGOLIA: 'Mongólia',
  MONTENEGRO: 'Montenegro',
  MONTSERRAT: 'Montserrate',
  MOROCCO: 'Marrocos',
  MOZAMBIQUE: 'Moçambique',
  MYANMAR: 'Mianmar',
  NAMIBIA: 'Namíbia',
  NAURU: 'Nauru',
  NEPAL: 'Nepal',
  NETHERLANDS: 'Holanda',
  NETHERLANDS_ANTILLES: 'Antilhas Holandesas',
  NEW_CALEDONIA: 'Nova Caledônia',
  NEW_ZEALAND: 'Nova Zelândia',
  NICARAGUA: 'Nicarágua',
  NIGER: 'Níger',
  NIGERIA: 'Nigéria',
  NIUE: 'Niue',
  NORFOLK_ISLAND: 'Ilha Norfolk',
  NORTH_MACEDONIA: 'Macedônia do Norte',
  NORTHERN_MARIANA_ISLANDS: 'Ilhas Marianas do Norte',
  NORWAY: 'Noruega',
  OMAN: 'Omã',
  PAKISTAN: 'Paquistão',
  PALAU: 'Palau',
  PALESTINIAN_TERRITORY: 'Território Palestino',
  PANAMA: 'Panamá',
  PAPUA_NEW_GUINEA: 'Papua Nova Guiné',
  PARAGUAY: 'Paraguai',
  PERU: 'Peru',
  PHILIPPINES: 'Filipinas',
  PITCAIRN: 'Ilhas Picárnia',
  POLAND: 'Polônia',
  PORTUGAL: 'Portugal',
  PUERTO_RICO: 'Porto Rico',
  QATAR: 'Catar',
  REUNION_ISLAND: 'Ilha da Reunião',
  ROMANIA: 'Romênia',
  RUSSIAN_FEDERATION: 'Federação Russa',
  RWANDA: 'Ruanda',
  SAINT_BARTHOLOMEW: 'São Bartolomeu',
  SAINT_HELENA: 'Santa Helena',
  SAINT_KITTS_AND_NEVIS: 'São Cristóvão e Nevis',
  SAINT_LUCIA: 'Santa Lúcia',
  SAINT_PIERRE_AND_MIQUELON: 'São Pedro e Miquelon',
  SAINT_VINCENT_AND_GRENADINES: 'São Vicente e Granadinas',
  SAINT_MARTIN_FRENCH_PART: 'Saint-Martin (parte francesa)',
  SAMOA: 'Samoa',
  SAN_MARINO: 'São Marino',
  SAO_TOME_AND_PRINCIPE: 'São Tomé e Príncipe',
  SAUDI_ARABIA: 'Arábia Saudita',
  SENEGAL: 'Senegal',
  SERBIA: 'Sérvia',
  SEYCHELLES: 'Seicheles',
  SIERRA_LEONE: 'Serra Leoa',
  SINGAPORE: 'Cingapura',
  SLOVAKIA: 'Eslováquia',
  SLOVENIA: 'Eslovênia',
  SOLOMON_ISLANDS: 'Ilhas Salomão',
  SOMALIA: 'Somália',
  SOUTH_AFRICA: 'África do Sul',
  SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS:
    'Geórgia do Sul e Ilhas Sandwich do Sul',
  SOUTH_SUDAN: 'Sudão do Sul',
  SPAIN: 'Espanha',
  SRI_LANKA: 'Sri Lanka',
  SUDAN: 'Sudão',
  SURINAME: 'Suriname',
  SVALBARD_AND_JAN_MAYEN_ISLANDS: 'Ilhas Svalbard e Jan Mayen',
  SWAZILAND: 'Suazilândia',
  SWEDEN: 'Suécia',
  SWITZERLAND: 'Suíça',
  SYRIAN_ARAB_REPUBLIC_SYRIA: 'República Árabe Síria (Síria)',
  TAIWAN_REPUBLIC_OF_CHINA: 'Taiwan, República da China',
  TAJIKISTAN: 'Tadjiquistão',
  TANZANIA_UNITED_REPUBLIC_OF: 'Tanzânia, República Unida da',
  THAILAND: 'Tailândia',
  TIMOR_LESTE: 'Timor-Leste',
  TOGO: 'Togo',
  TOKELAU: 'Toquelau',
  TONGA: 'Tonga',
  TRINIDAD_AND_TOBAGO: 'Trinidad e Tobago',
  TUNISIA: 'Tunísia',
  TURKEY: 'Turquia',
  TURKMENISTAN: 'Turcomenistão',
  TURKS_AND_CAICOS_ISLANDS: 'Ilhas Turcas e Caicos',
  TUVALU: 'Tuvalu',
  UGANDA: 'Uganda',
  UKRAINE: 'Ucrânia',
  UNITED_ARAB_EMIRATES: 'Emirados Árabes Unidos',
  UNITED_KINGDOM: 'Reino Unido',
  UNITED_STATES_OF_AMERICA: 'Estados Unidos da América',
  URUGUAY: 'Uruguai',
  US_MINOR_OUTLYING_ISLANDS: 'Ilhas Menores Distantes dos EUA',
  UZBEKISTAN: 'Uzbequistão',
  VANUATU: 'Vanuatu',
  VATICAN_CITY: 'Cidade do Vaticano',
  VENEZUELA_BOLIVARIAN_REPUBLIC: 'Venezuela (República Bolivariana)',
  VIETNAM: 'Vietnã',
  VIRGIN_ISLANDS_US: 'Ilhas Virgens, EUA',
  WALLIS_AND_FUTUNA_ISLANDS: 'Ilhas Wallis e Futuna',
  WESTERN_SAHARA: 'Saara Ocidental',
  YEMEN: 'Iémen',
  ZAMBIA: 'Zâmbia',
  ZIMBABWE: 'Zimbábue',
  SUCCESS_ADDRESS_SAVED_SUCCESSFULLY: 'Morada guardada com sucesso',
  ORDER_REQUEST_WAS_COMPLETED: 'A sua encomenda foi concluída com sucesso',
  ORDER_REQUEST_WAS_RECEIVED: 'Sua solicitação de pedido foi recebida',
  ORDER_NUMBER: 'Número do pedido',
  ORDER_COMPLETED_DETAILS_1:
    'Um e-mail com os detalhes da sua encomenda foi enviado para o endereço de e-mail que você forneceu.',
  ORDER_COMPLETED_DETAILS_2: 'Por favor, guarde-o para os seus registos.',
  ORDER_COMPLETED_DETAILS_3:
    'Pode visitar a página As Minhas Encomendas a qualquer momento para verificar o status do seu pedido.',
  ORDER_RECEIVED_DETAILS_1:
    'Os detalhes do pedido foram enviados para o endereço de e-mail fornecido.',
  ORDER_RECEIVED_DETAILS_2:
    'Agora, por favor, aguarde a aprovação de sua empresa.',
  ORDER_RECEIVED_DETAILS_3:
    'Você pode verificar o andamento a qualquer momento visitando a seção "Meus pedidos".',
  CONTINUE_SHOPPING: 'Voltar à loja',
  BUTTON_MY_ORDERS: 'As minhas encomendas',
  THANK_YOU: 'Obrigado',
  FITTING_SCHEDULE_NON_EXISTANT:
    'Lamentamos, mas não existem disponibilidades para agendamentos de fitting. Por favor contacte o seu gestor.',
  PROCEED_TO_MEASURES: 'Tirar Medidas',
  LAYOUT_VIEW: 'Esquema',
  IMAGE_VIEW: 'Imagem',
  CHECK_TERMS_SERVICE: 'Tem de declarar que as medidas estão corretas',
  REJECT: 'Rejeitar',
  PRIVACY_POLICY_CHECK:
    'Declaro que li e tomei conhecimento da Política de Privacidade da SKYPRO',
  PRIVACY_POLICY_CHECK_UNVALID:
    'Você deve aceitar a Política de Privacidade da SKYPRO para poder utilizar este portal',
  NO_MORE_STOCK_AVAILABLE: 'Não há mais stock disponível',
  ORDER_LIMIT_REACHED: 'Atingiu o limite de pedidos para este item'
}
