import React from 'react'

import PropTypes from 'prop-types'
import {Translate} from 'react-localize-redux'

import Currency from '../../../infra/utils/Currency'
import GetImage from '../../../shared/components/Image'
import {
  OrderProduct,
  ProductCategory,
  ProductOrderRowContainer,
  ProductPrice,
  ProductQuantity,
  ProductStatus,
  ProductThumb,
  ProductTitle
} from '../OrdersStyle'

const ProductOrderRow = ({product, order, user}) => (
  <ProductOrderRowContainer>
    <OrderProduct>
      <ProductThumb
        src={GetImage(
          product?.product?.productImage?.find((x) => x.cover)
        )}
      />
    </OrderProduct>
    <OrderProduct>
      <ProductCategory
        style={{
          color: user?.configuration?.layoutColors?.contentText
        }}
      >
        {
          product?.product?.mainCategory?.categoryTranslation?.find(
            (x) => x.languageId === user?.languageId
          )?.name
        }
      </ProductCategory>
      <ProductTitle>
        {
          product?.product?.productTranslation?.find(
            (x) => x.languageID === user?.languageId
          )?.name
        }
      </ProductTitle>
    </OrderProduct>
    <OrderProduct>
      <ProductQuantity>
        <Translate id='QUANTITY' />: {product?.quantity}
      </ProductQuantity>
      <ProductQuantity>
        {
          product?.color?.colorTranslation?.find(
            (x) => x.languageId === user?.languageId
          )?.name
        }
        {' / '}
        {product?.size?.nameEu}
      </ProductQuantity>
    </OrderProduct>
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%'
      }}
    >
      <ProductStatus style={{marginBottom: 0}}>
        <Translate id='STATUS' />:{' '}
        {
          product?.status?.orderStatusTranslation?.find(
            (x) => x.languageId === user?.languageId
          )?.name
        }
      </ProductStatus>
      <ProductStatus />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {product?.isCredits === 0 ? (
          <ProductPrice>
            {Currency.format(
              product?.unitTotalValueFinal,
              order?.currencyCode
            )}
          </ProductPrice>
        ) : (
          <ProductPrice style={{marginBottom: 0}}>
            {product?.quantity}{' '}
            {product?.quantity === 1 ? (
              <Translate id='CART_CREDIT' />
            ) : (
              <Translate id='CART_CREDITS' />
            )}
          </ProductPrice>
        )}
      </div>
    </div>
  </ProductOrderRowContainer>
)

ProductOrderRow.propTypes = {
  product: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

export default ProductOrderRow
