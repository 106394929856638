import React, {useState} from 'react'

import {ShoppingOutlined} from '@ant-design/icons'
import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect, useDispatch, useSelector} from 'react-redux'

import {ClearShoppingBag} from '../../../infra/requests/BagRequests'
import Currency from '../../../infra/utils/Currency'
import {bagDelete} from '../../../redux/data/bag/BagActions'
import usePreventScroll from '../../logic/hooks/usePreventScroll'
import {
  Bag,
  BagItemsSpan,
  BagList,
  BagRow,
  BagTitle,
  BottomContainer,
  CheckoutButton,
  IconSkyproClose,
  InnerBagRow,
  ListItem,
  MyBag,
  ClearBag,
  Subtotal,
  SubtotalResult,
  TopContainer,
  BagRowNoResults,
  NoItems
} from './BagStyles'
import CartItem from './CartItem'
import RelatedSideBar from './RelatedSideBar'

const ShoppingBag = ({user, open, closeBag}) => {
  const [isHover, setIsHover] = useState(false)
  const {
    bag: {bagItems, bagTotal},
    related
  } = useSelector((state) => state)
  const dispatch = useDispatch()
  usePreventScroll(open)

  const handleMouseEnter = () => {
    setIsHover(true)
  }

  const handleMouseLeave = () => {
    setIsHover(false)
  }

  const btnStyle = {
    transition: 'all 0.5s ease',
    color: isHover
      ? user.configuration?.layoutColors?.contentButtonText
      : user.configuration?.layoutColors?.contentText,
    border: `1px solid ${user.configuration?.layoutColors?.contentText}`,
    backgroundColor: isHover
      ? user.configuration?.layoutColors?.contentText
      : 'transparent'
  }

  const clearCart = async () => {
    const {success} = await ClearShoppingBag()

    if (success) {
      dispatch(bagDelete())
    }
  }

  return (
    <MyBag open={open}>
      <Bag open={open}>
        <TopContainer>
          <BagRow>
            <BagTitle>
              <ShoppingOutlined style={{marginRight: '18px'}} />
              <Translate id='MY_BAG' />
              <BagItemsSpan>
                {` ${bagItems.reduce(
                  (total, item) => (total += item.units),
                  0
                )}`}
              </BagItemsSpan>
              <IconSkyproClose onClick={closeBag} />
            </BagTitle>
            {bagItems.length !== 0 ? (
              <>
                <InnerBagRow>
                  <BagList>
                    <ListItem>
                      <Subtotal>
                        <Translate id='SUBTOTAL' />
                      </Subtotal>
                      <SubtotalResult>
                        {Currency.format(bagTotal, user.currencyCode)}
                      </SubtotalResult>
                    </ListItem>
                  </BagList>
                </InnerBagRow>
                <InnerBagRow>
                  <CheckoutButton
                    as='a'
                    href='/checkout'
                    style={btnStyle}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Translate id='CHECKOUT_NOW' />
                  </CheckoutButton>
                  <ClearBag
                    onClick={clearCart}
                    style={{
                      color: user.configuration?.layoutColors?.contentText
                    }}
                  >
                    <Translate id='CLEAR_BAG' />
                  </ClearBag>
                </InnerBagRow>
              </>
            ) : (
              <BagRowNoResults>
                <NoItems>
                  <Translate id='NO_ITEMS' />
                </NoItems>
              </BagRowNoResults>
            )}
          </BagRow>
        </TopContainer>
        <BottomContainer
          style={{height: 'calc(100% - 319px)', overflowY: 'auto'}}
        >
          {bagItems.map((item, index) => (
            <CartItem user={user} product={item} key={index} />
          ))}
        </BottomContainer>
      </Bag>
      {related?.length > 0 && (
        <RelatedSideBar {...{open, related, closeBag}} />
      )}
    </MyBag>
  )
}

ShoppingBag.propTypes = {
  closeBag: PropTypes.func,
  open: PropTypes.bool.isRequired,
  user: PropTypes.object,
  sideBar: PropTypes.bool
}

ShoppingBag.defaultProps = {
  user: {},
  sideBar: false,
  closeBag: () => {}
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default withLocalize(connect(mapStateToProps)(ShoppingBag))
