import {Progress, Steps} from 'antd'
import styled from 'styled-components'

import {
  Grey,
  Lightgrey,
  PageBackground,
  PrimaryColor,
  PrimaryTextColor,
  SecondaryColor,
  TertiaryColor
} from '../../shared/styles/_colors'
import {device} from '../../shared/styles/_responsive'
import {
  AddressTextTitle,
  Assistant,
  Assistant600,
  Assistant800,
  AssistantBold,
  BodyText,
  LabelText,
  MediaType,
  OverlineText,
  PanelTopSubTitle
} from '../../shared/styles/_texts'

export const PageSection = styled.section`
  background-color: ${PageBackground};
  padding-bottom: 20px;
  padding-top: 20px;
  flex-grow: 1;
`

export const PageWrapper = styled.div`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 50px;

  @media ${device.tablet} {
    padding: 0 20px;
    flex-direction: column;
  }
`

export const TitleWrapper = styled.div`
  padding: 0 50px;
  border: 1px solid transparent;

  @media ${device.tablet} {
    display: none;
  }
`

export const EachTitle = styled.div`
  max-width: 20%;
  width: 100%;
  display: inline-block;
  font-family: ${AssistantBold};
  font-size: ${OverlineText};
  color: ${SecondaryColor};
  text-transform: uppercase;
  text-align: left;
  opacity: 0.3;
`

export const OrderWrapper = styled.div`
  margin-bottom: 20px;
`

export const OrderRow = styled.div`
  border: 1px solid hsla(0, 0%, 71%, 0.16);
  background-color: ${TertiaryColor};
  padding: 30px 50px;
  font-family: ${Assistant600};
  text-transform: none;
  font-size: 15px;
  display: flex;
  justify-content: center;
`

export const InfoRow = styled.div`
  position: relative;
`

export const EachInfo = styled(EachTitle)`
  font-family: ${Assistant600};
  text-transform: none;
  font-size: 15px;
`

export const OrdersWrapper = styled.div`
  border: 1px solid hsla(0, 0%, 71%, 0.16);
  background-color: ${TertiaryColor};
  padding: 30px 50px;
  font-size: 0;
  margin-top: 20px;

  @media ${device.tablet} {
    padding: 30px 0 0;
    margin-bottom: ${({open}) => (open === '1' ? '0' : '20px')};
  }
`

export const OrderInformation = styled.div`
  position: relative;
`

export const InfoItem = styled.div`
  text-transform: none;
  font-size: ${AddressTextTitle};
  font-family: ${Assistant};
  max-width: 20%;
  width: 100%;
  display: inline-block;
  color: ${Grey};
  text-align: left;

  &:first-of-type {
    color: ${PrimaryColor};
    font-family: ${Assistant800};
  }

  &:nth-of-type(2) {
    color: ${PrimaryTextColor};
  }

  &:nth-of-type(3) {
    color: ${Grey};
  }

  &:nth-of-type(4) {
    position: relative;
  }

  &:last-of-type {
    color: ${PrimaryTextColor};
  }

  @media ${device.tablet} {
    display: block;
    max-width: 100%;
    padding: 5px 30px;

    &:nth-child(3) {
      margin-bottom: 10px;
    }

    &:nth-child(5) {
      padding-top: 15px;
      padding-bottom: 15px;
      text-align: right;
      position: relative;
    }

    &:nth-child(4) {
      position: relative;
      padding-top: 24px;
      padding-bottom: 24px;
      border-top: 1px solid hsla(0, 0%, 71%, 0.16);
      border-bottom: 1px solid hsla(0, 0%, 71%, 0.16);
    }
  }
`

export const InfoItemDesktop = styled.div`
  text-transform: none;
  font-size: ${AddressTextTitle};
  font-family: ${Assistant};
  max-width: 20%;
  width: 100%;
  display: inline-block;
  color: ${Grey};
  text-align: left;

  &:first-of-type {
    color: ${PrimaryColor};
    font-family: ${Assistant800};
  }

  &:nth-of-type(2) {
    color: ${PrimaryTextColor};
  }

  &:nth-of-type(3) {
    color: ${Grey};
  }

  &:nth-of-type(4) {
    position: relative;
  }

  &:last-of-type {
    color: ${PrimaryTextColor};
  }

  @media ${device.tablet} {
    display: block;
    max-width: 100%;
    padding: 5px 30px;

    &:nth-child(3) {
      margin-bottom: 10px;
    }

    &:nth-child(5) {
      padding-top: 15px;
      padding-bottom: 15px;
      text-align: right;
      position: relative;
    }

    &:nth-child(4) {
      position: relative;
      padding-top: 24px;
      padding-bottom: 24px;
      border-top: 1px solid hsla(0, 0%, 71%, 0.16);
      border-bottom: 1px solid hsla(0, 0%, 71%, 0.16);
    }
  }
`

export const ExpandButton = styled.a`
  display: block;
  position: absolute;
  top: -13px;
  right: 0;
  z-index: 8;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  transition: all 0.3s ease-in;
  transform: ${({open}) => (!open ? 'rotate(0deg)' : 'rotate(45deg)')};

  &::before {
    // visibility: ${({open}) => (!open ? 'visible' : 'hidden')};
    transform: translate(-50%, -50%) rotate(-90deg);
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 14px;
    height: 1px;
    background-color: white;
    color: white;
    z-index: 8;
  }

  &::after {
    transform: translate(-50%, -50%) rotate(0deg);
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 14px;
    height: 1px;
    background-color: white;
    color: white;
    z-index: 8;
  }

  @media ${device.tablet} {
    display: block;
    right: 30px;
  }
`

export const OrderSummary = styled.div`
  height: ${({open}) => (open === '1' ? 'auto' : '0')};
  overflow: ${({open}) => (open === '1' ? 'visible' : 'hidden')};
  transition: height 1s ease-in-out;
  background: ${TertiaryColor};
`

export const OrderTable = styled.div`
  font-size: 0;
  border-left: 1px solid hsla(0, 0%, 71%, 0.16);
  border-right: 1px solid hsla(0, 0%, 71%, 0.16);
`

export const OrderInfoTable = styled.div`
  font-size: 0;
  border-top: 1px solid hsla(0, 0%, 71%, 0.16);
  border-left: 1px solid hsla(0, 0%, 71%, 0.16);
  border-right: 1px solid hsla(0, 0%, 71%, 0.16);
`

export const OrderTableWrapper = styled.dl`
  position: relative;
`

export const OrderTableTitle = styled.dt`
  font-family: ${AssistantBold};
  font-size: ${OverlineText};
  text-align: left;
  color: ${Grey};
  text-transform: uppercase;
  display: block;
`

export const OrderTableItems = styled.dd`
  display: block;
  font-family: ${Assistant};
  font-size: ${BodyText};
  line-height: 2;
  text-align: left;
  color: ${PrimaryTextColor};
  white-space: pre-line;
  margin-bottom: 0px !important;

  &:nth-of-type(2) {
    font-size: 15px;
  }
`

export const LineText = styled.div``

export const LinkText = styled.a``

export const ProblemMailLink = styled.a`
  font-family: ${Assistant};
  font-size: ${PanelTopSubTitle};

  &:last-of-type {
    font-size: ${BodyText};
  }
`

export const OrderAside = styled.aside`
  display: inline-block;
  font-size: 0;
  width: 33.333%;
  padding: 20px 50px;
  vertical-align: top;
  border-right: 1px solid hsla(0, 0%, 71%, 0.16);
  min-height: 150px;

  @media ${device.laptopL} {
    padding: 20px 30px;
    min-height: auto;
    width: 100%;
    border: none;
    border-bottom: 1px solid hsla(0, 0%, 71%, 0.16);
  }
`

export const OrderInfoAside = styled.aside`
  display: inline-block;
  font-size: 0;
  width: 33.333%;
  padding: 20px 50px;
  vertical-align: top;
  border-right: 1px solid hsla(0, 0%, 71%, 0.16);

  @media ${device.laptopL} {
    padding: 20px 30px;
    min-height: auto;
    width: 100%;
    border: none;
    border-bottom: 1px solid hsla(0, 0%, 71%, 0.16);
  }
`

export const SummaryAside = styled(OrderAside)`
  @media ${device.laptopL} {
    padding: 20px 30px;
    min-height: auto;
    width: 100%;
    border: none;
    border-bottom: 1px solid hsla(0, 0%, 71%, 0.16);
  }
`

export const SummarySpan = styled.span`
  display: inline-block;
  width: 40%;
  text-align: right;
`

export const SummaryTitle = styled.p`
  display: inline-block;
  width: 60%;
`

export const SummaryTableItems = styled(OrderTableItems)`
  line-height: 1;
  margin-bottom: 0px !important;

  &:nth-of-type(2) {
    font-size: 12px;
  }
`

export const OrderStatusAside = styled.aside`
  border: none;
  display: inline-block;
  vertical-align: top;
  font-size: 0;
  padding: 20px 30px;
  min-height: 150px;

  @media ${device.laptopL} {
    padding: 20px 30px;
    min-height: auto;
    width: 100%;
  }
`

export const HalfTable = styled.dl`
  display: inline-block;
  width: 50%;
  position: relative;
`

export const StatusBarBig = styled.div`
  @media ${device.tablet} {
    display: none;
  }

  padding: 35px 0;
  border-top: 1px solid hsla(0, 0%, 65%, 0.16);
  border-bottom: 1px solid hsla(0, 0%, 65%, 0.16);
  background-color: ${TertiaryColor};
`

export const BigProgressBar = styled(Steps)``

export const ProductOrderRowContainer = styled.div`
  @media ${device.tablet} {
    padding: 20px 30px;
  }

  padding: 5px 50px;
  border-bottom: 1px solid hsla(0, 0%, 71%, 0.16);
  border-left: 1px solid hsla(0, 0%, 71%, 0.16);
  border-right: 1px solid hsla(0, 0%, 71%, 0.16);
  font-size: 0;
  position: relative;
  background: ${Lightgrey};

  &:last-child {
    margin-bottom: 20px;
  }
`

export const OrderProduct = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;

  &:first-of-type {
    max-width: 13.333%;
    width: 100%;

    @media ${device.tablet} {
      float: left;
      max-width: 40%;
    }
  }
  &:nth-of-type(2) {
    max-width: 23.333%;
    width: 100%;

    @media ${device.tablet} {
      max-width: 60%;
    }
  }
  &:nth-of-type(3) {
    max-width: 23.333%;
    width: 100%;

    @media ${device.tablet} {
      display: contents;
    }
  }
  &:nth-of-type(4) {
    max-width: 30%;
    width: 100%;

    @media ${device.tablet} {
      display: contents;
    }
  }
  &:nth-of-type(5) {
    max-width: 10%;
    width: 100%;
    text-align: right;

    @media ${device.tablet} {
      display: contents;
    }
  }
`

export const ProductThumb = styled.img`
  @media ${device.tablet} {
    top: 0px;
    position: relative;
  }

  width: 90px;
`

export const ProductCategory = styled.span`
  font-family: ${AssistantBold};
  font-size: ${MediaType};
  letter-spacing: 1.4px;
  text-align: center;
  text-transform: uppercase;
`

export const ProductTitle = styled.p`
  font-family: ${Assistant800};
  font-size: ${LabelText};
  line-height: 1.25;
  text-align: left;
  color: ${PrimaryTextColor};
  display: block;
`

export const ProductQuantity = styled(ProductTitle)`
  font-family: ${Assistant};
  font-size: ${BodyText};
  display: block;
  line-height: 1.57;
  text-align: left;
`

export const ProductStatus = styled(ProductTitle)`
  font-family: ${Assistant};
  font-size: ${BodyText};
  font-weight: normal;
  line-height: 1.57;
  text-align: left;
`

export const ProductPrice = styled(ProductTitle)`
  font-family: ${Assistant800};
  font-size: ${LabelText};
  letter-spacing: 1px;
  /* line-height: 2.14; */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ProgressBar = styled(Progress)`
  padding-right: 16px;

  @media ${device.tablet} {
    padding-right: 0px;
  }
`
