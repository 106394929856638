import {useEffect, useState} from 'react'

/**
 * Custom hook to handle service calls
 * @param {() => Promise<{success: boolean, data: any}>} callback - Service function to be called
 * @param {Array<any>} dependencies - Array of dependencies for useEffect
 * @returns {{
 *   data: Object,
 *   isLoading: boolean
 * }}
 */

const useService = (callback, dependencies) => {
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    callback()
      .then((response) => {
        if (response.success) {
          setData(response.data)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [...dependencies])

  return {data, isLoading}
}

export default useService
