import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import {localizeReducer} from 'react-localize-redux'

import BagReducer from './data/bag/BagReducer'
import InfoReducer from './data/info/InfoReducer'
import ProductReducer from './data/product/ProductReducer'
import RelatedReducer from './data/related/RelatedReducer'
import UserReducer from './data/user/UserReducer'

const RootReducer = {
  localize: localizeReducer,
  user: UserReducer,
  info: InfoReducer,
  products: ProductReducer,
  bag: BagReducer,
  related: RelatedReducer
}

const ReduxMiddlewares = [
  ...getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false
  })
]

const ReduxStore = configureStore({
  reducer: RootReducer,
  middleware: ReduxMiddlewares,
  devTools: window.env.NODE_ENV !== 'production'
})

export default ReduxStore
