import React from 'react'

import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'

import GetImage from '../Image'
import {
  Recommended,
  RecommendedImage,
  RecommendedLink,
  RecommendedList,
  RecommendedName,
  RecommendedPrice,
  SideBar,
  SideBarContent,
  SideBarTitle
} from './BagStyles'

const RelatedSideBar = ({open, related, activeLanguage, closeBag}) => (
  <SideBar $open={open}>
    <SideBarContent>
      <SideBarTitle>
        <Translate id='YOU_WILL_LIKE' />
      </SideBarTitle>
      <RecommendedList>
        {related.map((product) => (
          <Recommended key={product?.productId}>
            <RecommendedLink
              to={`/products/${product.productId}`}
              onClick={() => closeBag()}
            >
              <RecommendedImage
                src={GetImage(
                  product?.product?.productImage.find((x) => x?.cover)
                )}
                alt={
                  product?.product?.productImage.find((x) => x?.cover)
                    ?.image?.filename
                }
              />
              <RecommendedName>
                {
                  product?.product?.productTranslation?.find(
                    (x) => x?.languageId === activeLanguage?.code
                  )?.name
                }
              </RecommendedName>
              <RecommendedPrice>
                {product?.product?.totalValue}€
              </RecommendedPrice>
            </RecommendedLink>
          </Recommended>
        ))}
      </RecommendedList>
    </SideBarContent>
  </SideBar>
)

RelatedSideBar.propTypes = {
  open: PropTypes.bool.isRequired,
  related: PropTypes.array.isRequired,
  activeLanguage: PropTypes.object.isRequired,
  closeBag: PropTypes.func
}

RelatedSideBar.defaultProps = {
  closeBag: undefined
}

export default withLocalize(RelatedSideBar)
