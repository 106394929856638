import {createReducer} from '@reduxjs/toolkit'

import {relatedSave, relatedEdit, relatedDelete} from './RelatedActions'

const initialState = {}

const RelatedReducer = createReducer(initialState, {
  [relatedSave]: (state, action) => action.payload,
  [relatedEdit]: (state, action) => ({...action.payload, ...state}),
  [relatedDelete]: () => initialState
})

export default RelatedReducer
