import {useMemo} from 'react'

import {useSelector} from 'react-redux'

import {GetSingleProduct} from '../../../infra/requests/ProductRequests'
import useService from '../../../shared/logic/hooks/useService'

/**
 * Custom hook to fetch and manage product details
 * @param {string} productId - The ID of the product to fetch
 * @returns {{
 *   isLoading: boolean,
 *   productDetails: Object,
 *   colorObject: Array,
 *   sizesObject: Array,
 *   technologiesObject: Array,
 *   relatedProducts: Array<Object>
 * }}
 */

const useProductDetails = (productId) => {
  const relatedProductsData = useSelector((state) => state.related)

  const {
    data: singleProductData,
    isLoading: isProductLoading
  } = useService(() => GetSingleProduct(productId), [productId])

  const productData = useMemo(
    () => ({
      colorObject: singleProductData?.product?.productCombination?.map(
        (color) => color.color
      ),
      sizesObject: singleProductData?.product?.productCombination?.map(
        (sizes) => sizes.size
      ),
      technologiesObject: singleProductData?.product?.productTechnology.map(
        (technologies) => technologies
      )
    }),
    [singleProductData]
  )

  const filteredProductData = useMemo(
    () =>
      relatedProductsData?.reduce((acc, product) => {
        if (product.productId !== Number(productId)) {
          acc.push({
            ...product,
            colorList: [
              ...new Set(
                product.product.productCombination.map(
                  (combo) => combo.color.html
                )
              )
            ]
          })
        }
        return acc
      }, []),
    [singleProductData]
  )

  return {
    ...productData,
    isLoading: isProductLoading,
    productDetails: singleProductData,
    relatedProducts: filteredProductData?.slice(0, 3)
  }
}

export default useProductDetails
