import React from 'react'

import PropTypes from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import Currency from '../../../infra/utils/Currency'
import formatDate from '../../../shared/utils/formatDate'
import {
  ExpandButton,
  InfoItem,
  InfoItemDesktop,
  OrderInformation,
  OrdersWrapper,
  ProgressBar
} from '../OrdersStyle'
import OrderItemSummary from './OrderItemSummary'

const getOrderPercentage = (order) =>
  order?.orderRelatedID === 0
    ? // Regular flow
      order?.orderStatus?.orderStatusId === 12
      ? '0.00' // Pending
      : order?.orderStatus?.orderStatusId === 1
      ? '16.66' // Processing
      : order?.orderStatus?.orderStatusId === 2
      ? '33.33' // Out-of-stock
      : order?.orderStatus?.orderStatusId === 3
      ? '50.00' // Confirmed
      : order?.orderStatus?.orderStatusId === 4
      ? '66.66' // Shipped
      : order?.orderStatus?.orderStatusId === 5
      ? '83.33' // Delivered
      : order?.orderStatus?.orderStatusId === 6
      ? '100.00' // Cancelled
      : '0.00'
    : // Return / Exchange flow
    order?.orderStatus?.orderStatusId === 7
    ? '0.00' // Waiting for Package
    : order?.orderStatus?.orderStatusId === 8
    ? '25.00' // Package received
    : order?.orderStatus?.orderStatusId === 9
    ? '50.00' // Approved
    : order?.orderStatus?.orderStatusId === 10
    ? '75.00' // Denied
    : order?.orderStatus?.orderStatusId === 11
    ? '100.00' // Refund completed
    : '0.00'

const OrderItem = ({
  open,
  setOpen,
  regularDataSteps,
  returnDataSteps,
  order,
  windowWidth,
  user
}) => (
  <>
    <OrdersWrapper
      key={order?.orderID}
      open={open.status && order?.orderID === open.id ? '1' : '0'}
    >
      <OrderInformation>
        <InfoItem
          style={{
            color: user.configuration?.layoutColors?.contentText
          }}
        >
          #{order?.orderRelatedID > 0 ? order?.number : order?.orderID}
        </InfoItem>
        <InfoItem>
          {formatDate(order?.date)?.dayOfMonth}{' '}
          {formatDate(order?.date)?.fullMonthName},{' '}
          {formatDate(order?.date)?.year}
        </InfoItem>
        <InfoItem>
          {order?.isPartially ? <Translate id='PARTIALLY' /> : ''}
          {order?.isPartially ? ' ' : ''}
          {
            order?.orderStatus?.orderStatusTranslation?.find(
              (x) => x.languageId === user.languageId
            )?.name
          }
        </InfoItem>
        <InfoItem>
          <ProgressBar
            showInfo={false}
            percent={getOrderPercentage(order)}
            style={{width: '100%'}}
          />
        </InfoItem>
        <>
          {windowWidth <= 768 ? (
            <>
              {!open.status && (
                <InfoItemDesktop
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <strong>
                    <Translate id='ORDER_TOTAL' />
                  </strong>
                  {Currency.format(
                    order?.usesCredits ? 0 : order?.totalValue,
                    order?.currencyCode
                  )}
                </InfoItemDesktop>
              )}
            </>
          ) : (
            <InfoItemDesktop style={{width: 'auto'}}>
              {Currency.format(
                order?.usesCredits ? 0 : order?.totalValue,
                order?.currencyCode
              )}
            </InfoItemDesktop>
          )}
        </>

        <ExpandButton
          open={order?.orderID === open.id ? open.status : ''}
          onClick={() =>
            setOpen({
              id: order?.orderID,
              status: !open.status
            })
          }
          style={{
            backgroundColor: user.configuration?.layoutColors?.contentText
          }}
        />
      </OrderInformation>
    </OrdersWrapper>
    <OrderItemSummary
      open={open}
      order={order}
      regularDataSteps={regularDataSteps}
      returnDataSteps={returnDataSteps}
    />
  </>
)

OrderItem.propTypes = {
  order: PropTypes.object.isRequired,
  open: PropTypes.object.isRequired,
  setOpen: PropTypes.func.isRequired,
  regularDataSteps: PropTypes.array,
  returnDataSteps: PropTypes.array,
  windowWidth: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired
}

OrderItem.defaultProps = {
  regularDataSteps: undefined,
  returnDataSteps: undefined
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default withLocalize(connect(mapStateToProps)(OrderItem))
