import React, {useEffect, useState} from 'react'

import {PropTypes} from 'prop-types'
import {Translate, withLocalize} from 'react-localize-redux'
import {connect} from 'react-redux'

import {
  GetAllDataSteps,
  GetAllEmployeeOrders
} from '../../infra/requests/CheckoutRequests'
import PageTopPanel from '../../shared/components/panels/PageTopPanel'
import {
  getFromStorage,
  saveToStorage,
  STORAGE_KEYS
} from '../../shared/utils/storage'
import OrderList from './components/OrderList'
import OrderListHeader from './components/OrderListHeader'
import {PageSection, PageWrapper} from './OrdersStyle'

const Orders = ({user, translate}) => {
  const [open, setOpen] = useState({id: null, status: false})
  const [orders, setOrders] = useState([])
  const [isLoadingOrders, setIsLoadingOrders] = useState(false)
  const [regularDataSteps, setRegularDataSteps] = useState()
  const [returnDataSteps, setReturnDataSteps] = useState()
  const [orderCount, setOrderCount] = useState(0)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const initialOrderSortValue = getFromStorage(
      STORAGE_KEYS.ORDER_SORT_VALUE
    )
    const GetOrders = async () => {
      setIsLoadingOrders(true)

      const {data, success} = await GetAllEmployeeOrders(
        initialOrderSortValue
      )

      if (success) {
        setOrders(data)

        const result = await GetAllDataSteps()

        if (result.success) {
          setRegularDataSteps(result.data?.regularFlowOrder)
          setReturnDataSteps(result.data?.returnExchangeFlowOrder)

          setIsLoadingOrders(false)
        }
      }

      setOrderCount(data?.length || 0)
    }

    GetOrders()
  }, [])

  const handleOrderSortChange = async (sortValue) => {
    setIsLoadingOrders(true)
    saveToStorage(STORAGE_KEYS.ORDER_SORT_VALUE, sortValue)
    try {
      const {success, data} = await GetAllEmployeeOrders(sortValue)
      if (success) setOrders(data)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoadingOrders(false)
    }
  }

  return (
    <>
      <PageTopPanel
        user={user}
        title={<Translate id='ORDERS_TITLE' />}
        subtitle={`${
          orderCount > 2
            ? orderCount + translate('ORDERS_FOUND')
            : orderCount + translate('ORDER_FOUND')
        }`}
        type='withdrop'
        onOrderSortChange={handleOrderSortChange}
      />
      <PageSection>
        <PageWrapper>
          <OrderListHeader />
          <OrderList
            orders={orders}
            isLoading={isLoadingOrders}
            open={open}
            setOpen={setOpen}
            regularDataSteps={regularDataSteps}
            returnDataSteps={returnDataSteps}
            windowWidth={windowWidth}
          />
        </PageWrapper>
      </PageSection>
    </>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
})

Orders.propTypes = {
  translate: PropTypes.func.isRequired
}

export default withLocalize(connect(mapStateToProps)(Orders))
