export const STORAGE_KEYS = {
  ORDER_SORT_VALUE: 'orderSortValue',
  USER_PREFERENCES: 'userPreferences'
}

export const saveToStorage = (key, value) => {
  localStorage.setItem(
    key,
    typeof value === 'object' ? JSON.stringify(value) : value
  )
}

export const getFromStorage = (key) => {
  const item = localStorage.getItem(key)
  if (!item) return null

  try {
    return JSON.parse(item)
  } catch {
    return item
  }
}
